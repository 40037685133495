import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { PendingActionsRow } from "./PendingActionsRow";
import dashboardStyles from "../dealerDashBoard/dashboardStyles";
import styles from "../dealerDashBoard/DashboardStyles.module.scss";
import PendingActionClient from "../../../shared/clients/PendingActionClient";

class PendingActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
    };
  }

  componentDidMount() {
    function getPendingActions() {
      this.props.hideOrShow(true, true);
      if (this.props.isNsc) {
        return PendingActionClient.getNscPendingActionsNotifications(
          this.props.user
        );
      } else {
        return Promise.resolve({
          pendingActions: this.props.user.pendingActions,
        });
      }
    }

    getPendingActions
      .call(this)
      .then((data) => {
        this.pendingActions = this.sortByDate(data.pendingActions);
      })
      .catch(() => {
        this.pendingActions = [];
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ renderApp: true });
      });
  }

  sortByDate(pendingActions) {
    pendingActions.sort(function (a, b) {
      const itemA = new Date(a.dueDate.split("/").reverse());
      const itemB = new Date(b.dueDate.split("/").reverse());

      return itemA - itemB;
    });
    return pendingActions;
  }

  render() {
    return (
      this.state.renderApp && (
        <>
          <div
            style={dashboardStyles.header}
            className={styles.pendingActionsHeader}
          >
            {this.props.t("DealerDashboard.adminAndPrice")}
            <span data-testid="pendingActionsCount">
              {" "}
              ({this.pendingActions.length})
            </span>
          </div>
          <Table data-testid="actionsTable" key="actionsTable">
            <PendingActionHeader t={this.props.t} />
            <TableBody>
              {this.pendingActions.map((pendingAction, index) => {
                {
                  if (
                    pendingAction.attributes &&
                    pendingAction.attributes["vehicleLines"]
                  ) {
                    return pendingAction.attributes["vehicleLines"].map(
                      (vehicleLine, vehicleIndex) => {
                        return PendingActionsRow(
                          pendingAction,
                          index + "-" + vehicleIndex,
                          this.props,
                          vehicleLine
                        );
                      }
                    );
                  } else {
                    return PendingActionsRow(pendingAction, index, this.props);
                  }
                }
              })}
            </TableBody>
          </Table>
        </>
      )
    );
  }
}

const PendingActionHeader = (props) => {
  return (
    <TableHead>
      <TableRow
        style={dashboardStyles.dashboardHeader}
        className={styles.tableHeaderLine}
      >
        <TableCell style={dashboardStyles.typeHeader}>
          <div className={styles.alignStart}>
            {props.t("AdminAndPricingDashboard.type")}
          </div>
        </TableCell>
        <TableCell style={dashboardStyles.orderIdHeader}>
          <div className={styles.alignStart}>
            {props.t("AdminAndPricingDashboard.description")}
          </div>
        </TableCell>
        <TableCell style={dashboardStyles.dateHeader}>
          <div className={styles.alignStart}>
            {props.t("AdminAndPricingDashboard.dueDate")}
          </div>
        </TableCell>
        <TableCell style={dashboardStyles.actionHeader}>
          <div className={styles.alignStart}>
            {props.t("AdminAndPricingDashboard.action")}
          </div>
        </TableCell>
        <TableCell style={dashboardStyles.emptyHeader} />
      </TableRow>
    </TableHead>
  );
};

export default withTranslation("emp")(PendingActions);
