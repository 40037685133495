import React, { Component } from "react";
import { Button, Grid, TableCell } from "@material-ui/core";
import styles from "./LoadDealerPricing.module.scss";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { PAYMENT_THRESHOLD_LIMIT } from "../../../../common/Constants";
import ClearIcon from "@material-ui/icons/Clear";
import SortingIcon from "../../../../shared/sortingIcon/SortingIcon";
import AlertToolTip from "../../../../shared/sharedToolTip/AlertToolTip";

class LoadDealerPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimitFormatted: "",
      validationInvalidFormat: "",
      validationRequired: "",
      sortDir: undefined,
      sortKey: undefined,
    };

    ValidatorForm.addValidationRule("changeInPrice", (value, model) => {
      let changeInPrice = true;
      this.props.selectedPrices.forEach((item) => {
        changeInPrice = !(
          (model === item.currencyAdjustedPrice) ===
          parseFloat(value).toFixed(2)
        );
      });
      this.props.applyBtn && this.props.applyBtn(changeInPrice);
      return true;
    });

    this.deleteTermsDialogProps = {
      title: this.props.t("TermsandConditionsHistory.deleteTitle"),
      bodyText: this.props.t("TermsandConditionsHistory.deleteBody"),
      confirm: this.props.t("TermsandConditionsHistory.confirm"),
      cancel: this.props.t("TermsandConditionsHistory.cancel"),
    };

    this.dealerInventaryApprovePermission =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "dealerInventoryApprove:write"
      );

    this.disableCheckbox =
      //Disable checkbox if the permissions not found
      this.dealerInventaryApprovePermission === false ||
      this.props.user.dealerType === "BRAN";

    this.showAlertIcon =
      // Only show alert Icon if user can take action
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "dealerInventoryApprove:write"
      );
  }

  componentDidMount() {
    this.setState({
      maxLimitFormatted: PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
        ? PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
            .maxLimitFormatted
        : "999,999.99",
      validationInvalidFormat:
        this.props.t("PaymentThreshold.validations.invalidFormat") || "",
      validationRequired: "Value is required" || "",
    });
  }

  handleConfirm = () => {
    this.props.deleteTerms(this.version);
  };

  handleSort = (sortKey, newDirection, sortByString) => {
    this.setState({ sortKey: sortKey, sortDir: newDirection });
    this.props.loadDealerInventoryPricingHistory(sortByString);
  };

  deleteTermsDialog = (version) => {
    this.version = version;
  };

  displayDepositPrice = (item, defaultCurrency) => {
    if (
      item.currencyAdjustedPrice !== null &&
      (!!item.currencyAdjustedPrice || item.currencyAdjustedPrice >= 0)
    ) {
      return (
        <div>
          <span>
            {defaultCurrency ? defaultCurrency.symbol : ""}{" "}
            {parseFloat(item.currencyAdjustedPrice).toFixed(2)}
          </span>
        </div>
      );
    }
  };

  displayPreviousPrice = (item, defaultCurrency) => {
    if (
      item.previousAdjustedPrice !== null &&
      item.previousAdjustedPrice >= 0
    ) {
      return (
        <div className={styles.previousDiv}>
          <span id="model" className={styles.previousPrice}>
            {defaultCurrency ? defaultCurrency.symbol : ""}{" "}
            {parseFloat(item.previousAdjustedPrice).toFixed(2)}
          </span>
        </div>
      );
    } else {
      return (
        <div className={styles.previousDiv}>
          <span id="model" className={styles.previousPrice}>
            -
          </span>
        </div>
      );
    }
  };

  loadTableCellHeader = (style, divStyle, column, displayColumn, editable) => {
    return (
      <TableCell style={style}>
        <div>
          {displayColumn && (
            <div className={divStyle} id="header">
              {this.props.t("UploadDealerPrice." + column)}
            </div>
          )}
          {editable && (
            <div
              className={`${styles.Edit} ${
                this.props.editButtonDisable && styles.disabled
              }`}
              id="editReservations"
              onClick={this.props.editReservations}
            >
              <div className={styles.RectangleStart}>&nbsp;</div>
              <div className={styles.RectangleEnd}>&nbsp;</div>
            </div>
          )}
        </div>
      </TableCell>
    );
  };

  showDeleteIcon = (index) => {
    return (
      this.props.termsAndConditionsHistory.length > 1 &&
      index === 0 &&
      this.props.termsAndConditionsHistory[0].canBeDeleted
    );
  };
  showInventoryConfirmation = (v) => {
    //step-1 // open confirm dialog and pass the selected vin
    this.props.showInventoryConfirmation(v);
  };

  roundToTwoDecimal = (num) => {
    if (num % 1 !== 0) {
      let value = +(Math.round(num + "e+2") + "e-2");
      const length =
        value &&
        value.toString().split(".")[1] &&
        value.toString().split(".")[1].length;
      if (length === 0) {
        value = value + ".00";
      } else if (length === 1) {
        value = value + "0";
      }
      return value;
    } else {
      return num ? num.toString() + ".00" : "-";
    }
  };
  getSortString = (sortKey) => {
    let newDirection = "DSC";
    if (sortKey === this.state.sortKey) {
      newDirection = this.state.sortDir === "ASC" ? "DSC" : "ASC";
    }

    if (sortKey === "currencyAdjustedPrice") {
      newDirection === "ASC"
        ? this.handleSort(sortKey, newDirection, "currencyAdjustedPrice.ASC")
        : this.handleSort(sortKey, newDirection, "currencyAdjustedPrice.DSC");
    } else if (sortKey === "modelYear") {
      newDirection === "ASC"
        ? this.handleSort(sortKey, newDirection, "modelYear.ASC")
        : this.handleSort(sortKey, newDirection, "modelYear.DSC");
    }
  };

  checkedCheckbox = (price) => {
    if (this.props.isMEXMarket) {
      return price.vehicleSuppressedByDealer;
    }
    return price.priceModified
      ? price.checked
      : price.checked || price.published;
  };

  render() {
    const { defaultCurrency } = this.props.localizedInfo || {
      defaultCurrency: { symbol: "" },
    };
    return (
      <div id="inventoryPrices">
        <Grid
          container
          spacing={0}
          mt={1}
          className={`${styles.tableRowHeader} ${styles.cellSpacing} pagePaddingLeft pagePaddingRight`}
        >
          {this.props.showValidatdColumn &&
            this.showAlertIcon &&
            !this.props.isMEXMarket && (
              <Grid item className={styles.alertCell} />
            )}
          {this.props.showValidatdColumn && (
            <Grid item className={styles.reservationEditPrice}>
              <span
                id="model"
                className={`${styles.priceValidatedAllCheckBox} ${
                  this.props.userDetails.market === "ARG"
                    ? styles.AllCheckBoxLeftPadding
                    : ""
                } ${this.props.isMEXMarket ? "" : styles.flex}`}
              >
                {this.props.isMEXMarket
                  ? this.props.t("UploadDealerPrice.suppress")
                  : this.props.t("UploadDealerPrice.validated")}
                {!this.props.isMEXMarket && (
                  <CheckBox
                    id="checkAllBoxes"
                    checked={this.props.allChecked}
                    isDisabled={this.disableCheckbox}
                    onChange={this.props.checkAllCheckBox}
                  />
                )}
              </span>
            </Grid>
          )}
          <Grid item className={styles.specificationColumn}>
            <span
              id="model"
              className={`${styles.reservationInputGridModel} ${styles.specification_header}`}
            >
              {this.props.t("UploadDealerPrice.specification")}
            </span>
          </Grid>
          <Grid item className={styles.vinColumn}>
            <span id="model" className={styles.reservationInputGridModel}>
              {this.props.t("UploadDealerPrice.vin")}
            </span>
          </Grid>
          <Grid
            item
            className={`${styles.modelYearColumn} ${styles.sort}`}
            onClick={() => this.getSortString("modelYear")}
          >
            <div id="model" className={styles.reservationInputGridModel}>
              {this.props.t("UploadDealerPrice.modelYear")}
              <SortingIcon
                currentKey="modelYear"
                sortKey={this.state.sortKey}
                sortDirection={this.state.sortDir}
              />
            </div>
          </Grid>
          {!this.props.hideUsdPrice && !this.props.isMEXMarket && (
            <Grid item className={styles.usdConversionColumn}>
              <span id="model" className={styles.reservationInputGridModel}>
                {this.props.t("UploadDealerPrice.usdCustomerPrice")}
              </span>
            </Grid>
          )}
          {!this.props.isMEXMarket && (
            <Grid item className={styles.previousPriceColumn}>
              <span id="model" className={styles.reservationInputGridModel}>
                {this.props.t("UploadDealerPrice.previousAdjustedPrice")}
              </span>
            </Grid>
          )}
          {!this.props.isMEXMarket && (
            <Grid item className={styles.previousPriceEffectiveDateColumn}>
              <span id="model" className={styles.reservationInputGridModel}>
                {this.props.t(
                  "UploadDealerPrice.previousAdjustedPriceEffectiveDate"
                )}
              </span>
            </Grid>
          )}
          {!this.props.isMEXMarket && (
            <Grid
              item
              className={`${styles.arsAdjustedPriceColumn}  ${styles.sort}`}
              onClick={() => this.getSortString("currencyAdjustedPrice")}
            >
              <div className={styles.reservationInputGridModel}>
                {this.props.t("UploadDealerPrice.arsAdjustedPrice")}
                <SortingIcon
                  currentKey="currencyAdjustedPrice"
                  sortKey={this.state.sortKey}
                  sortDirection={this.state.sortDir}
                />
              </div>
            </Grid>
          )}
          {!this.props.isMEXMarket && (
            <Grid item className={styles.spacer}>
              <HasPermissionTo
                perform={["dealerInventoryApprove:write"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => (
                  <div
                    className={`${styles.Edit} ${
                      this.props.editButtonDisable && styles.disabled
                    }`}
                    id="editReservations"
                    data-testid="editReservations"
                    onClick={this.props.editReservations}
                  >
                    <div className={styles.RectangleStart}>&nbsp;</div>
                    <div id="pencilIcon" className={styles.RectangleEnd}>
                      &nbsp;
                    </div>
                  </div>
                )}
              />
            </Grid>
          )}
          <Grid item className={styles.effectiveDateColumn}>
            <div className={styles.reservationInputGridRightCell}>
              {this.props.t("UploadDealerPrice.effectiveDate")}
            </div>
          </Grid>
          {this.props.showValidatdColumn && (
            <Grid item className={styles.blankColumn}></Grid>
          )}
        </Grid>
        <div className={styles.topMarginGridContent}>
          {this.props.selectedPrices &&
            this.props.selectedPrices.map((price, index) => (
              <Grid
                container
                spacing={0}
                mt={1}
                className={`${styles.cellSpacing} pagePaddingLeft pagePaddingRight`}
                key={"selectedPrice-" + price.vin + "-" + index}
              >
                {this.props.showValidatdColumn &&
                  this.showAlertIcon &&
                  !this.props.isMEXMarket && (
                    <Grid item className={styles.alertCell}>
                      {!price.published && (
                        <div className={styles.alertIcon}>
                          <AlertToolTip
                            body={this.props.t(
                              "ModifyReservationPrice.priceNeedsToBeValidated"
                            )}
                          />
                        </div>
                      )}
                    </Grid>
                  )}
                {this.props.showValidatdColumn && (
                  <Grid item className={styles.reservationEditPrice}>
                    <div className={styles.checkBoxDiv}>
                      <CheckBox
                        id={`checkbox${index}`}
                        key={`checkbox${index}-${price && price.checked}`}
                        value={index}
                        checked={this.checkedCheckbox(price)}
                        isDisabled={this.disableCheckbox}
                        onChange={this.props.clickCheckBox}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item className={styles.specificationColumn}>
                  <span
                    id="model"
                    style={{ justifyContent: "flex-start" }}
                    className={styles.reservationInputGridModel}
                  >
                    {price.inventorySpecification}
                  </span>
                </Grid>

                <Grid item className={styles.vinColumn}>
                  <span id="model" className={styles.reservationInputGridModel}>
                    {price.vin}
                  </span>
                </Grid>
                <Grid item className={styles.modelYearColumn}>
                  <span id="model" className={styles.reservationInputGridModel}>
                    {price.modelYear}
                  </span>
                </Grid>
                {!this.props.hideUsdPrice && (
                  <Grid item className={styles.usdConversionColumn}>
                    <span
                      id="model"
                      className={styles.reservationInputGridModelPrice}
                    >
                      USD{" "}
                      {price.currencyAdjustedPrice > 0
                        ? this.roundToTwoDecimal(
                            price.currencyAdjustedPrice *
                              this.props.conversionRate
                          )
                        : ""}
                    </span>
                  </Grid>
                )}

                {!this.props.isMEXMarket && (
                  <Grid item className={styles.previousPriceColumn}>
                    {this.displayPreviousPrice(price, defaultCurrency)}
                  </Grid>
                )}
                {!this.props.isMEXMarket && (
                  <Grid
                    item
                    className={styles.previousPriceEffectiveDateColumn}
                  >
                    <div className={styles.previousDiv}>
                      {price.previousPriceEffectiveDate === "" ||
                      price.previousPriceEffectiveDate === null
                        ? "-"
                        : price.previousPriceEffectiveDate}
                    </div>
                  </Grid>
                )}
                {!this.props.isMEXMarket && (
                  <Grid
                    item
                    className={`${styles.reservationInputGridModel} ${styles.reservationEditPrice} ${styles.currencyAdjustedPriceColumn}`}
                  >
                    <div>
                      {this.props.editMode ? (
                        <TextValidator
                          label={defaultCurrency ? defaultCurrency.symbol : ""}
                          autoComplete="off"
                          name={price.vin}
                          ref={this.priceRef}
                          variant="outlined"
                          key={"checkbox-row" + { index } + "-value-"}
                          value={
                            price.currencyAdjustedPrice === null ||
                            price.currencyAdjustedPrice === ""
                              ? ""
                              : price.currencyAdjustedPrice
                          }
                          onChange={this.props.updateInventoryPrice}
                          validators={[
                            "required",
                            "matchRegexp:^[0-9]*(\\.[0-9]{0,2})?$",
                            "minNumber:0",
                            "changeInPrice:" + price.vin,
                          ]}
                          errorMessages={[
                            this.state.validationRequired,
                            this.state.validationInvalidFormat,
                            this.state.validationInvalidFormat,
                            this.state.maxLimitFormatted,
                            this.state.validationInvalidFormat,
                          ]}
                        />
                      ) : (
                        this.displayDepositPrice(price, defaultCurrency)
                      )}
                    </div>
                  </Grid>
                )}
                {!this.props.isMEXMarket && (
                  <Grid item className={styles.spacer}></Grid>
                )}
                <Grid item className={styles.effectiveDateColumn}>
                  <span
                    id="model"
                    className={styles.reservationInputGridRightCell}
                  >
                    {price.effectiveDate}
                  </span>
                </Grid>
                {this.props.showValidatdColumn && (
                  <Grid item className={styles.clearButtonColumn}>
                    <HasPermissionTo
                      perform={["dealerInventoryDelete:write"]}
                      permissions={this.props.user.permissions.rolePermissions}
                      render={() => (
                        <Button
                          className={styles.deleteButton}
                          id={`cancelAdd_${price.vin}`}
                          data-testid={`cancelAdd_${price.vin}`}
                          onClick={() =>
                            this.showInventoryConfirmation(price.vin)
                          }
                          size="small"
                        >
                          <ClearIcon />
                        </Button>
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(LoadDealerPricing);
