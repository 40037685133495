import React, { Component } from "react";
import fordStyles from "./SharedToolTip.module.scss";
import lincolnStyles from "./LincolnSharedToolTip.module.scss";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import AlertIcon from "../../imgs/error/error.png";

const StyledClear = withStyles({
  root: {
    color: "#102b4e",
  },
})(Clear);

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 350,
  },
})(Tooltip);

// Props: open, title, body, openTooltip(), closeTooltip(), closeTestid, openTestid, isLincolnBrand

class AlertToolTip extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
  }

  closeTooltip = (event) => {
    event.stopPropagation();
    this.setState({ open: false });
  };

  openTooltip = (event) => {
    event.stopPropagation();
    this.setState({ open: true });
  };

  render() {
    const styles = this.props.isLincolnBrand ? lincolnStyles : fordStyles;
    return (
      <StyledTooltip
        placement="bottom-start"
        open={this.state.open}
        title={
          <div>
            <button
              onClick={this.closeTooltip}
              className={styles.exitButton}
              data-testid={this.props.closeTestid}
            >
              <StyledClear />
            </button>
            <div className={styles.header}>{this.props.title}</div>
            <div className={styles.bodyContent}>{this.props.body}</div>
          </div>
        }
      >
        <img
          alt="Tooltip"
          data-testid={this.props.openTestid}
          className={styles.tooltipImg}
          onClick={(event) => this.openTooltip(event)}
          src={AlertIcon}
        />
      </StyledTooltip>
    );
  }
}

export default withTranslation("emp")(AlertToolTip);
