import React, { Component } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./App.scss";
import "../styles/sharedStyles.scss";
import PreviewTermsConditions from "../shared/previewTermsConditions/PreviewTermsConditions";
import TermsDeclined from "../components/declineTerms/TermsDeclined";
import Dealer from "./pure/Dealer";
import Help from "../components/help/help";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Footer from "../shared/Footer/Footer";
import QuickStart from "../components/quickStart/QuickStart";
import ProgressBarTabs from "../components/progressBar/ProgressBarTabs";
import AccessDeniedAsBevDisabled from "../components/accessDenied/AccessDeniedAsBevDisabled";
import AccessDeniedAsNotOnBoarded from "../components/accessDenied/AccessDeniedAsNotOnBoarded";
import InvalidRoute from "../components/accessDenied/InvalidRoute";
import BusinessSettings from "../components/businessSetting/BusinessSettings";
import DealerDashboard from "../components/dashboard/dealerDashBoard/DealerDashboard";
import { logMsg } from "../common/Logger";
import withIdleTimer from "../common/WithIdleTimer";
import DealerPricing from "../components/pricing/pricingWrapper/dealerPricing/DealerPricing";
import HasPermissionTo from "../common/HasPermissionTo";
import DealerOnboardingClient from "../shared/clients/DealerOnboardingClient";
import LocalizedInfoClient from "../shared/clients/LocalizedInfoClient";
import DealerGroupingClient from "../shared/clients/DealerGroupingClient";
import PendingActionClient from "../shared/clients/PendingActionClient";
import ErrorScreen from "../errors/ErrorScreen";
import AccessDeniedAsNscNotOnboarded from "../components/accessDenied/AccessDeniedAsNscNotOnboarded";
import { IS_SA_MARKET } from "../utils/EmpUtil";
import NscOnboardingClient from "../shared/clients/NscOnboardingClient";
import Nsc from "./pure/Nsc";

class DealerApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderApp: false,
      shouldNavigationBeRestricted: false,
      dealerGroup: [],
      dealerGroupFiltered: [],
      currentDealer: new Dealer(
        this.props.token,
        this.props.userDetails,
        this.props.history
      ),
      padding: "70px",
    };
    this.localizedInfo = {};
    this.lang = undefined;

    // Listening for route change
    this.props.history.listen(() => this.setNavigationRestriction());

    this.dealerChanged = this.dealerChanged.bind(this);
    this.navigateToRoute = this.navigateToRoute.bind(this);
    this.getPendingActions = this.getPendingActions.bind(this);
    this.onReservationSave = this.onReservationSave.bind(this);
    this.onDecline = this.onDecline.bind(this);
    this.user = new Nsc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );
  }

  componentDidMount() {
    this.getLocalizedInfo();
  }

  onDealerDecline() {
    this.onBoardingStatus = "PENDING";
    this.navigateToRoute("");
  }

  onReservationSave() {
    this.onBoardingStatus = "ACCEPTED";
    window.location.href = "/";
  }

  onDecline() {
    this.onBoardingStatus = "DECLINED";
    this.navigateToRoute("declined");
  }

  getLocalizedInfo() {
    LocalizedInfoClient.getLocalizedInfo(this.state.currentDealer)
      .then((response) => {
        this.localizedInfo = Object.assign(
          {},
          { ...this.localizedInfo },
          { ...response }
        );
        this.lang = this.localizedInfo.defaultLanguage.isocode;

        this.props.i18n.changeLanguage(
          this.lang.substring(0, 2) + "_" + this.props.userDetails.market
        );
      })
      .catch((error) => logMsg("the error is: ", error))
      .finally(() => {
        this.hasNscOnboarded();
      });
  }

  isBevEnabledAndOnboarded() {
    this.props.hideOrShow(true);
    DealerGroupingClient.getDealerGroup(this.state.currentDealer)
      .then((data) => {
        let dealer;
        if (data.length > 1) {
          dealer = data.filter(
            (dealer) =>
              dealer.dealerType === "MDLR" || dealer.dealerType === "HUB"
          )[0];
        } else {
          dealer = data[0];
        }

        const bevAndIceDealers = data.sort((a, b) => {
          if (a.dealerType === "MDLR" || a.dealerType === "HUB") {
            return -1;
          } else if (b.dealerType === "MDLR" || b.dealerType === "HUB") {
            return 1;
          } else {
            return 0;
          }
        });

        dealer.dealerType === "MDLR" || dealer.dealerType === "HUB"
          ? this.setState({
              dealerGroup: bevAndIceDealers,
              dealerGroupFiltered: bevAndIceDealers.filter(
                (dealer) => dealer.dealerType !== "SDLR"
              ),
            })
          : this.setState({
              dealerGroup: bevAndIceDealers,
              dealerGroupFiltered: bevAndIceDealers,
            });

        this.state.currentDealer.setDealerGroup(this.state.dealerGroup);
        this.updateCurrentDealer(dealer, true);
        this.onBoardingStatus = dealer.onBoardingStatus;
        this.dealerActiveStatus = dealer.dealerActiveStatus;
      })
      .catch(() => {})
      .finally(() => {
        this.determinePendingActionStatuses();
      });
  }

  determinePendingActionStatuses() {
    PendingActionClient.getHasNscCompletedPA(this.state.currentDealer, 1)
      .then((hasNscCompletedBasicPAs) => {
        const currentDealer = this.state.currentDealer;
        currentDealer.hasNscCompletedBasicPAs = hasNscCompletedBasicPAs;
        this.setState({ currentDealer });
      })
      .finally(() => {
        this.getPendingActions().finally(() =>
          this.performFinalActions.call(this)
        );
      });
  }

  hasNscOnboarded() {
    NscOnboardingClient.isOnboarded(this.user).then((hasNscOnboarded) => {
      if (hasNscOnboarded.onboarded) {
        this.isBevEnabledAndOnboarded();
      } else {
        this.onBoardingStatus = "NSC_NOT_ONBOARDED";
        this.navigateToRoute("nsc-not-onboarded");
        this.performFinalActions.call(this);
      }
    });
  }

  performFinalActions() {
    this.setNavigationRestriction();
    this.props.hideOrShow(false);
    this.setState({ renderApp: true });
  }

  setNavigationRestriction() {
    if (this.onBoardingStatus !== "ACCEPTED") {
      this.setState({ shouldNavigationBeRestricted: true });
    } else {
      const isAllowedPath = this.isCurrentPathAllowedToShowHeaderAndFooter(
        this.props.history.location.pathname
      );
      this.setState({ shouldNavigationBeRestricted: !isAllowedPath });
    }
  }

  isCurrentPathAllowedToShowHeaderAndFooter(currentPathName) {
    const paths = [
      "/help",
      "/business-settings",
      "/dashboard",
      "/pricing/dealerpricing",
      "/pricing",
      "/",
    ];

    return paths.includes(currentPathName);
  }

  navigateToRoute(link) {
    this.props.history.push(`/${link}`);
  }

  updateDealerPhoneAndEmail(dealer, email, phone) {
    const index = this.state.dealerGroup.indexOf(
      this.state.dealerGroup.filter(
        (d) => d.completeDealerId === dealer.dealerId
      )[0]
    );

    const dealerGroupCopy = JSON.parse(JSON.stringify(this.state.dealerGroup));
    dealerGroupCopy[index].emailAddress = email;
    dealerGroupCopy[index].phoneNumber = phone;

    this.setState({ dealerGroup: dealerGroupCopy });
  }

  updateDealerEmpMembership(dealer) {
    const index = this.state.dealerGroup.indexOf(
      this.state.dealerGroup.filter(
        (d) => d.completeDealerId === dealer.completeDealerId
      )[0]
    );

    const dealerGroup = this.state.dealerGroup;
    dealerGroup[index].status = dealer.status;

    this.setState({ dealerGroup });
  }

  isMainViewingSDLR = (dealer) => {
    // Returns true if a main dealer is currently viewing an SDLR
    return (
      this.state.currentDealer.isLoggedInUserMDLR &&
      dealer.dealerType === "SDLR"
    );
  };

  async getPendingActions(
    dealer = this.state.currentDealer,
    updatePAOnly = true
  ) {
    this.props.hideOrShow(true);
    await PendingActionClient.getDealerPendingActionsNotifications(
      dealer,
      false
    )
      .then((data) => {
        this.props.hideOrShow(false);
        if (updatePAOnly) {
          this.state.currentDealer.updatePendingActions(data.pendingActions);
        } else {
          this.setState({ currentDealer: dealer }, () => {
            this.state.currentDealer.updatePendingActions(data.pendingActions);
          });
        }
      })
      .catch((error) => {
        this.props.hideOrShow(false);
        this.state.currentDealer.updatePendingActions([]);
        logMsg("the error is: ", error);
      });
  }

  updateCurrentDealer(dealer, showAll = false, getPendingActions = false) {
    const newDealer = this.state.currentDealer.updateCurrentDealer(
      dealer,
      showAll
    );
    const hasPendingActionPermission =
      this.state.currentDealer.permissions.rolePermissions.hasOwnProperty([
        "pendingActionQueue:view",
      ]);
    if (getPendingActions && hasPendingActionPermission) {
      this.getPendingActions(newDealer, false);
    } else {
      this.setState({ currentDealer: newDealer });
    }
  }

  dealerChanged(dealer, showAll = false) {
    this.updateCurrentDealer(dealer, showAll, true);
  }

  setPadding = (padding) => {
    if (padding > 70) {
      this.setState({ padding: padding + "px" });
    }
  };

  getProgressBarTabs() {
    return IS_SA_MARKET(this.user.market)
      ? ProgressBarTabs().onboardingSADealer
      : ProgressBarTabs().onboardingDealer;
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      perform: perform,
      ...rest
    }) => (
      <HasPermissionTo
        perform={perform}
        permissions={this.state.currentDealer.permissions.rolePermissions}
        render={() => (
          <Route
            {...rest}
            render={(props) =>
              this.onBoardingStatus === "ACCEPTED" ? (
                <Component
                  {...rest.customprops}
                  {...this.props}
                  {...props.match.params}
                />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        )}
      />
    );

    const DeclinedRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={() =>
          this.onBoardingStatus === "DECLINED" ? (
            <Component {...rest.customprops} {...this.props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );

    return this.state.renderApp ? (
      <Router history={this.props.history}>
        <div className="appWrapper" id="appWrapper">
          {this.state.shouldNavigationBeRestricted ? null : (
            <div style={{ paddingTop: this.state.padding }}>
              <GlobalNavBar
                {...this.props}
                dealerGroup={this.state.dealerGroup}
                onDealerChanged={this.dealerChanged}
                user={this.state.currentDealer}
                urlPath={window.location.pathname}
                setPadding={this.setPadding}
                navigateToRoute={this.navigateToRoute}
              />
            </div>
          )}
          <Switch>
            <ProtectedRoute
              exact
              path="/dashboard"
              component={withIdleTimer(DealerDashboard)}
              perform={["dashBoardPage"]}
              customprops={{
                user: this.state.currentDealer,
                isMainViewingSDLR: this.isMainViewingSDLR,
                onDealerChanged: this.dealerChanged.bind(this),
                lang: this.lang,
              }}
            />
            <ProtectedRoute
              exact
              path="/business-settings"
              component={withIdleTimer(BusinessSettings)}
              perform={["businessSettingsPage"]}
              customprops={{
                isMainViewingSDLR: this.isMainViewingSDLR,
                getPendingActions: this.getPendingActions.bind(this),
                user: this.state.currentDealer,
                lang: this.lang,
                dealerGroup: this.state.dealerGroup,
                localizedInfo: this.localizedInfo,
                updateDealerGroupDetails:
                  this.isBevEnabledAndOnboarded.bind(this),
                updateDealerPhoneAndEmail:
                  this.updateDealerPhoneAndEmail.bind(this),
                updateDealerEmpMembership:
                  this.updateDealerEmpMembership.bind(this),
                onDealerChanged: this.dealerChanged.bind(this),
                permissions: this.props.userDetails.permissions,
              }}
            />
            <Route
              exact
              path="/help"
              component={withIdleTimer(() => (
                <Help
                  {...this.props}
                  market={this.state.currentDealer.market}
                />
              ))}
            />
            <DeclinedRoute
              exact
              path="/declined"
              component={withIdleTimer(TermsDeclined)}
              customprops={{
                user: this.state.currentDealer,
                onDecline: this.onDealerDecline.bind(this),
              }}
            />
            <ProtectedRoute
              exact
              path="/pricing/dealerpricing"
              perform={["dealerPricingTab"]}
              component={withIdleTimer(() => (
                <DealerPricing
                  {...this.props}
                  localizedInfo={this.localizedInfo}
                  user={this.state.currentDealer}
                  isMainViewingSDLR={this.isMainViewingSDLR}
                  onDealerChanged={this.dealerChanged}
                />
              ))}
            />

            <Route exact path="/denied" component={AccessDeniedAsBevDisabled} />
            <Route
              exact
              path="/notonboarded"
              component={AccessDeniedAsNotOnBoarded}
            />
            <Route
              exact
              path="/nsc-not-onboarded"
              component={AccessDeniedAsNscNotOnboarded}
            />
            <Route
              exact
              path="/"
              render={() =>
                this.onBoardingStatus === "ACCEPTED" ||
                (this.onBoardingStatus === "DECLINED" &&
                  this.dealerActiveStatus === "IN_ACTIVE") ? (
                  <Redirect
                    to={{
                      pathname: "/dashboard",
                    }}
                  />
                ) : this.onBoardingStatus === "DECLINED" ? (
                  <Redirect
                    to={{
                      pathname: "/declined",
                    }}
                  />
                ) : // NOTE: ONBOARDINGNOTCOMPLETED = means that this dealer cannot onboard self
                this.onBoardingStatus === "ONBOARDINGNOTCOMPLETED" ? (
                  <Redirect
                    to={{
                      pathname: "/notonboarded",
                    }}
                  />
                ) : (
                  this.onBoardingStatus === "PENDING" && (
                    <QuickStart
                      {...this.props}
                      user={this.state.currentDealer}
                      isMainViewingSDLR={this.isMainViewingSDLR}
                      dealerGroup={this.state.dealerGroupFiltered}
                      history={this.props.history}
                      onboardingClient={DealerOnboardingClient}
                      localizedInfo={this.localizedInfo}
                      onSave={this.onReservationSave}
                      onDecline={this.onDecline}
                      progressBarTabs={this.getProgressBarTabs()}
                      Preview={PreviewTermsConditions}
                      fromQuickstart={true}
                    />
                  )
                )
              }
            />
            <Route
              exact
              path="/error"
              render={(props) => <ErrorScreen {...props} />}
            />
            <Route component={withIdleTimer(InvalidRoute)} />
          </Switch>
          {this.state.shouldNavigationBeRestricted ? null : (
            <Footer permissions={this.props.userDetails.permissions} />
          )}
        </div>
      </Router>
    ) : null;
  }
}

export default DealerApp;
