import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL, IS_SA_MARKET } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

let dealers = [];

export default class DealerOnboardingClient {
  static baseUrl = BASE_URL;

  static async onBoard(user, data, dealerGroup) {
    if (data.termsAcceptedStatus) {
      if (data.termsAcceptedStatus === "yes") {
        if (IS_SA_MARKET(user.market)) {
          return await this.completeOnboarding(
            user,
            [],
            this.getSADealers(dealerGroup)
          );
        }
        return Promise.resolve();
      } else if (data.termsAcceptedStatus === "no") {
        return await this.declineTerms(user, dealerGroup);
      }
    }
    if (data.dealers) {
      data.dealers.forEach((dealer) => {
        dealer.storeId = parseInt(dealer.storeId);
      });
      dealers = data.dealers;
    }
    if (data.reservations) {
      return await this.completeOnboarding(user, data.reservations, dealers);
    }
  }

  static declineTerms(user, dealerGroup) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/decline`;
    const dealers = [];
    dealerGroup.forEach((dealer) => {
      const newDealer = {
        commonId: dealer.commonId,
        dealerId: dealer.completeDealerId,
      };
      dealers.push(newDealer);
    });

    const data = {
      dealers: dealers,
      dealerUser: {
        commonName: user.commonName,
        userId: user.userId,
      },
    };

    return axios
      .post(url, data, getHeaders(user.token))
      .then(() => Promise.resolve("go to decline page"))
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static completeOnboarding(user, deposits, dealers) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/onboarding`;
    const data = {
      dealers: dealers,
      reservationDeposits: deposits,
      dealerUser: {
        userId: user.userId,
        commonName: user.commonName,
      },
    };

    return axios
      .post(url, data, getHeaders(user.token))
      .then(() => Promise.resolve("go to dashboard"))
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static isOnboarded(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/onboardingstatus`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getSADealers(dealerGroup) {
    dealerGroup.forEach((dealer) => {
      dealer.storeId = null;
    });

    return dealerGroup;
  }
}
