import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import emp_en from "../shared/translations/en";
import emp_de from "../shared/translations/de";
import emp_fr from "../shared/translations/fr";
import emp_no from "../shared/translations/no";
import emp_nl from "../shared/translations/nl";
import emp_fr_ca from "../shared/translations/fr_CA";
import emp_es from "../shared/translations/es";
import emp_it from "../shared/translations/it";
import emp_da from "../shared/translations/da";
import emp_fr_BE from "../shared/translations/fr_BE";
import emp_nl_BE from "../shared/translations/nl_BE";
import emp_fi from "../shared/translations/fi";
import TranslationsClient from "../components/translations/TranslationsClient.js";

const language = i18next.language || "en_AUS";

const languageUrl = TranslationsClient.getDefaultLanguage();

const backendOptions = {
  type: "backend",
  crossDomain: false,
  allowMultiLoading: false,
  loadPath: languageUrl + "{{lng}}",
};

const options = {
  initImmediate: false,
  debug: true,
  lng: language,
  fallbackLng: language,
  ns: ["emp"],
  defaultNS: "t",
  react: {
    wait: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
    defaultTransParent: "div",
  },
};

const resources = {
  en: {
    emp: emp_en,
  },
  de: {
    emp: emp_de,
  },
  fr: {
    emp: emp_fr,
  },
  no: {
    emp: emp_no,
  },
  nl: {
    emp: emp_nl,
  },
  fr_CA: {
    emp: emp_fr_ca,
  },
  fr_BEL: {
    emp: emp_fr_BE,
  },
  nl_BEL: {
    emp: emp_nl_BE,
  },
  es: {
    emp: emp_es,
  },
  it: {
    emp: emp_it,
  },
  da: {
    emp: emp_da,
  },
  fi: {
    emp: emp_fi,
  },
};

options["backend"] = backendOptions;

i18next.use(XHR).init(options);

// Loading translations from local shared folder if translation service fails
i18next.on("failedLoading", function (lng, ns) {
  const resourceLng =
    lng === ("fr_CA" || "fr_BEL" || "nl_BEL") ? lng : lng.substr(0, 2);
  i18next.addResourceBundle(lng, ns, resources[resourceLng][ns], false, true);
});

export default i18next;
