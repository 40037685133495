export default class UiDateUtils {
  static formattedDate(date) {
    return date === undefined || date === null ? "" : date.split("-").join("/");
  }

  static formatYYYYMMDDToEUWithoutTime(date) {
    if (date) {
      date = date.split("T")[0];
      if (date) {
        const dates = date.split("-");
        return dates[2] + "/" + dates[1] + "/" + dates[0];
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  static formatDateMmDdYyyy(date) {
    return (
      date &&
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }

  static formatDateYYYYMMDD(year, month, day) {
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  static formatDateDDMMYYYWithOutDashes(year, month, day) {
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const formattedDate = day.toString() + month.toString() + year.toString();
    return formattedDate;
  }

  static saveDateFormat(date) {
    return date === null ? "" : date.split("/").join("-");
  }

  static formatDateddmmyyyy(str) {
    if (typeof str === "undefined" || str === null) {
      return "Not Specified";
    }
    return (str = str.slice(0, 2) + "/" + str.slice(2, 4) + "/" + str.slice(4));
  }

  static formatDateDDMMYYYY(date) {
    return (
      date &&
      date.getDate() + "/" + date.getMonth() + 1 + "/" + date.getFullYear()
    );
  }

  static getDateInYYYYMMDD(date) {
    return (
      date &&
      date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate()
    );
  }

  static formatTodayDate(str) {
    const year = str.substring(0, 4);
    const month = str.substring(5, 7);
    const day = str.substring(8, 10);
    return new Date(year, month - 1, day);
  }

  static formatDateDDMMYYYYToMMDDYYYY(dateStr, currentSeparator, newSeparator) {
    if (dateStr) {
      const dates = dateStr.split(currentSeparator);
      if (dates.length === 3) {
        return dates[1] + newSeparator + dates[0] + newSeparator + dates[2];
      }
    }
    return null;
  }

  static formatyyyymmddToDDMMYYY(dateStr, currentSeparator) {
    if (dateStr) {
      const dates = dateStr.split(currentSeparator);
      if (dates.length === 3) {
        return dates[2] + dates[1] + dates[0];
      }
    }
    return null;
  }
}
