import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL, INVENTORY_SERVICE_URL } from "../../utils/EmpUtil";
import { is401, is404 } from "../../errors/ErrorConstants";

class UploadDealerPriceClient {
  static baseUrl = BASE_URL;
  static invetoryPricingUrl = INVENTORY_SERVICE_URL;

  static getInventoryPricingData(user, path) {
    let url;
    if (user.userType === "dealer") {
      url = `${this.invetoryPricingUrl}/dealer/${user.dealerId}/${path}`;
    }
    return axios.get(url, getHeaders(user.token));
  }

  static downloadInvPriceTemplate(user, colunmHeaders) {
    function addAllDealerObjects(user) {
      const listOfDealers = [];
      let newDealerObj = {};

      user.dealerGroup.forEach((dealer) => {
        newDealerObj = {
          dealerId: dealer.completeDealerId,
          commonId: dealer.commonId,
        };
        listOfDealers.push(newDealerObj);
      });

      return listOfDealers;
    }

    const listOfDealers = addAllDealerObjects(user);
    const data = {
      columnHeaders: colunmHeaders,
      dealerInfos: listOfDealers,
    };
    const url = `${this.invetoryPricingUrl}/dealer/download-inventory-price-template`;

    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static retrieveDealerInventoryPrices(user) {
    return this.getInventoryPricingData(user, "inventory-prices-data")
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error) || is404(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static uploadDealerPrice(user, formData) {
    const url = `${this.invetoryPricingUrl}/dealer/save-inventory-prices`;

    return axios
      .post(url, formData, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static persistAndPublishToIAL(user, requestBody) {
    const url = `${this.invetoryPricingUrl}/dealer/update-inventory-prices`;

    return axios
      .put(url, requestBody, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static deleteInventoryPrices(vin, user) {
    const url = `${this.invetoryPricingUrl}/dealer/delete-inventory-price?vin=${vin}&dealerId=${user.dealerId}`;
    return axios
      .delete(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default UploadDealerPriceClient;
