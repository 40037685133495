const check = (
  accessPermissions,
  actions,
  condition = true,
  toggleOn = true
) => {
  if (!accessPermissions || !condition || !toggleOn) {
    return false;
  }
  //checking whether the actions have any of the permissions in the accessPermissions
  for (const action in actions) {
    if (accessPermissions.hasOwnProperty(actions[action])) {
      return true;
    }
  }
  return actions.length === 0;
};

const HasPermissionTo = (props) =>
  check(props.permissions, props.perform, props.condition, props.toggleOn)
    ? props.render()
    : props.noRender();

HasPermissionTo.defaultProps = {
  // eslint-disable-next-line
  render: () => null,
  // eslint-disable-next-line
  noRender: () => null,
};

export default HasPermissionTo;
