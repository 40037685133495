import React, { Component } from "react";
import styles from "./DealerReporting.module.scss";
import { Grid } from "@material-ui/core";
import Item from "../../../../shared/item/Item";
import SortingIcon from "../../../../shared/sortingIcon/SortingIcon";
import SortData from "../../../../utils/SortUtil";
import { withTranslation } from "react-i18next";
import DownloadButtonV2 from "../../../../shared/buttons/DownloadButtonV2";
import {
  base64FileDownload,
  fileDownload,
} from "../../../../utils/FileDownloadUtil";
import { logMsg } from "../../../../common/Logger";
import DealerReportingClient from "../../../../shared/clients/DealerReportingClient";
import { IS_SA_MARKET } from "../../../../utils/EmpUtil";

class DealerReporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: null,
      sortDirection: null,
      reports: [
        {
          key: "report1",
          name: this.props.t("dealerReporting.report1Name"),
          description: this.props.t("dealerReporting.report1Description"),
          condition: !IS_SA_MARKET(this.props.user.market),
          downloadLink: this.downloadOrderStatusReport.bind(this),
        },
        {
          key: "report6",
          name: this.props.t("dealerReporting.report6Name"),
          description: this.props.t("dealerReporting.report6Description"),
          condition: IS_SA_MARKET(this.props.user.market),
          downloadLink: this.downloadInventoryPricingHistoryReport.bind(this),
        },
      ],
    };
  }

  downloadInventoryPricingReport = (report) => {
    DealerReportingClient.downloadInventoryPricingReportInCsv(
      this.props.user,
      this.props.t("dealerReporting.inventoryReportColumnHeadersForDealer")
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) +
          " - " +
          this.getTodayDateDDMMYYYY() +
          ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      });
  };

  downloadInventoryPricingHistoryReport = (report) => {
    DealerReportingClient.downloadInventoryPricingHistoryReport(
      this.props.user,
      this.props.t("dealerReporting.inventoryReportColumnHeadersForDealer")
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) +
          " - " +
          this.getTodayDateDDMMYYYY() +
          ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      });
  };

  getTodayDateDDMMYYYY() {
    const today = new Date();
    return `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
  }

  downloadOrderStatusReport = (report) => {
    DealerReportingClient.downloadOrderStatusReportInCsv(
      this.props.user,
      this.props.lang
    )
      .then((res) => {
        const filename = this.props.t(report.description) + ".csv";
        const BOM = "\uFEFF";
        const response = BOM + res;
        fileDownload(
          decodeURIComponent(response),
          "text/csv;charset=utf-8;",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      });
  };

  sortColumns = (sortByCol) => {
    let sortDir = "DSC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }
    const that = this;
    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.reports,
      function (sortedData) {
        that.setState({
          sortDirection: sortDir,
          sortKey: sortByCol,
          reports: sortedData,
        });
      }
    );
  };

  render() {
    return (
      <>
        <Grid container className={`pagePadding ${styles.gridHeader}`}>
          <Grid
            item
            md={3}
            className={styles.typeHeader}
            onClick={() => this.sortColumns("name")}
          >
            <Item value={this.props.t("dealerReporting.type")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="name"
                sortKey={this.state.sortKey}
                sortDirection={this.state.sortDirection}
              />
            </span>
          </Grid>
          <Grid
            item
            md={7}
            className={styles.typeHeader}
            onClick={() => this.sortColumns("description")}
          >
            <Item value={this.props.t("dealerReporting.document")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="description"
                sortKey={this.state.sortKey}
                sortDirection={this.state.sortDirection}
              />
            </span>
          </Grid>
          <Grid item md={2} className={styles.actionHeader}>
            <Item value={this.props.t("dealerReporting.action")} />
          </Grid>
        </Grid>

        {this.state.reports.map(
          (report) =>
            report.condition && (
              <React.Fragment key={report.key}>
                <Grid
                  container
                  className={`pagePaddingLeft pagePaddingRight ${styles.gridBody}`}
                >
                  <Grid item md={3} className={styles.colType}>
                    <div>{report.name}</div>
                  </Grid>
                  <Grid item md={7} className={styles.colDocument}>
                    <div>{report.description}</div>
                  </Grid>
                  <Grid item md={2} className={`${styles.end}`}>
                    <DownloadButtonV2
                      download={() => report.downloadLink(report)}
                      text={this.props.t("dealerReporting.download")}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )
        )}
      </>
    );
  }
}

export default withTranslation("emp")(DealerReporting);
