import React, { Component } from "react";
import SubNav from "../../../shared/subnav/SubNav";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../common/HasPermissionTo";
import DealerReporting from "./DealerReporting/DealerReporting";
import PendingActions from "../pendingActions/PendingActions";
import DealerNotifications from "../dealerNotifications/DealerNotifications";
import { displayError, LOAD_ERROR } from "../../../errors/ErrorConstants";

class DealerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      renderApp: false,
      hasError: [],
    };
  }

  componentDidMount() {
    try {
      this.props.user.dashboardPage
        ? this.setState({ selectedTab: this.props.user.dashboardPage })
        : this.updateDashboardTab(this.state.selectedTab);
      this.setState({ renderApp: true });
    } catch (e) {
      this.setState({ hasError: [LOAD_ERROR] });
    }
  }

  handleTabChange = (event, selectedTab) => {
    this.updateDashboardTab(selectedTab);
    this.setState({ selectedTab });
  };

  updateDashboardTab = (selectedTab) => {
    this.props.user.updateDashboardTab &&
      this.props.user.updateDashboardTab(selectedTab);
  };

  getTabs() {
    const tabs = [
      {
        menuName: this.props.t("ProgressBar.notifications"),
        enabled: true,
        id: "dashboard",
      },
    ];
    this.props.user.userType === "dealer" &&
      tabs.push({
        menuName: this.props.t("ProgressBar.reporting"),
        enabled: true,
        id: "reporting",
      });
    return tabs;
  }

  render() {
    if (displayError(this.state.hasError)) {
      throw new Error(this.state.hasError[0]);
    }

    return (
      this.state.renderApp && (
        <div id="dashboard" className="pageWrapper">
          <HasPermissionTo
            perform={["dealerReporting:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => {
              return (
                <SubNav
                  dashboard={true}
                  selectedTab={this.state.selectedTab}
                  title={this.props.t("GlobalNavBar.dashboard")}
                  action={this.handleTabChange}
                  tabs={this.getTabs()}
                  {...this.props}
                />
              );
            }}
            noRender={() => {
              return (
                <SubNav
                  id="subNav"
                  dashboard={true}
                  title={this.props.t("GlobalNavBar.dashboard")}
                  {...this.props}
                />
              );
            }}
          />
          {this.state.selectedTab === 0 ? (
            <>
              <HasPermissionTo
                perform={["pendingActionQueue:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => <PendingActions {...this.props} />}
              />

              <HasPermissionTo
                perform={["notificationsQueue:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => <DealerNotifications {...this.props} />}
              />
            </>
          ) : (
            <HasPermissionTo
              perform={["dealerReporting:view"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 1}
              render={() => <DealerReporting {...this.props} />}
            />
          )}
        </div>
      )
    );
  }
}

export default withTranslation("emp")(DealerDashboard);
