import React, { Component } from "react";
import DealershipDetails from "./dealershipdetails/DealershipDetails";
import { withTranslation } from "react-i18next";
import AcceptTermsAndConditions from "./termsAndConditions/AcceptTermsAndConditions";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import LoadTermsAndConditions from "./termsAndConditions/LoadTermsAndConditions";
import EmpMembership from "./EmpMembership";
import SubNav from "../../shared/subnav/SubNav";
import HasPermissionTo from "../../common/HasPermissionTo";

class DealerBusinessSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.startingTab,
      renderPage: false,
    };
  }

  componentDidMount() {
    this.props.user.businessSettingsPage
      ? this.setState({ selectedTab: this.props.user.businessSettingsPage })
      : this.props.user.updateBusinessSettingsTab(this.state.selectedTab);
    this.setState({ renderPage: true });
  }

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
    this.props.user.updateBusinessSettingsTab(selectedTab);
    this.props.resetFromPending();
  };

  getTabs() {
    return [
      {
        menuName: this.props.t("ProgressBar.empMembership"),
        enabled: true,
        id: "empMembership",
      },
      {
        menuName: this.props.t("ProgressBar.dealershipDetails"),
        enabled: true,
        id: "dealership-details",
      },
    ];
  }

  renderTermsConditionsTab() {
    return this.props.isDealerAcceptingTerms ? (
      <AcceptTermsAndConditions {...this.props} />
    ) : (
      <>
        <div className="pagePaddingLeft pagePaddingTop">
          <InfoIconWithMessage
            message={this.props.t("AcceptTermsConditions.subheader")}
          />
        </div>
        <LoadTermsAndConditions {...this.props} />
        <HasPermissionTo
          perform={["empMembership:view"]}
          permissions={this.props.user.permissions.rolePermissions}
          render={() => {
            return <EmpMembership {...this.props} />;
          }}
        />
      </>
    );
  }

  render() {
    return (
      this.state.renderPage && (
        <>
          <SubNav
            dashboard={false}
            selectedTab={this.state.selectedTab}
            title={this.props.t("GlobalNavBar.settings")}
            action={this.handleTabChange}
            tabs={this.getTabs()}
            restrictDropdown={this.props.restrictDropdown}
            {...this.props}
          />
          <div className="pageWrapper">
            {this.state.selectedTab === 0 && this.renderTermsConditionsTab()}
            {this.state.selectedTab === 1 && (
              <DealershipDetails {...this.props} />
            )}
          </div>
        </>
      )
    );
  }
}

export default withTranslation("emp")(DealerBusinessSettings);
