import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import styles from "./termsDeclined.module.scss";
import "../../styles/sharedStyles.scss";
import FordLogo from "../../common/FordLogo";
import HasPermissionTo from "../../common/HasPermissionTo";
import PageHeader from "../../shared/pageHeader/PageHeader";
import { withTranslation } from "react-i18next";
import { IS_SA_MARKET } from "../../utils/EmpUtil";

class TermsDeclined extends Component {
  render() {
    return (
      <div className="pageWrapper">
        <PageHeader
          {...this.props}
          title={this.props.t("TermsDeclined.header")}
        />
        <div className={styles.center}>
          <FordLogo className={styles.brandLogo} />
          <div className={`${styles.declinedTitle}`}>
            {this.props.t("TermsDeclined.header")}
          </div>
        </div>

        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["deniedSteps:view"]}
          render={() => (
            <>
              <div className={styles.declinedSubheader}>
                {this.props.t("TermsDeclined.subheader")}
              </div>
              {!IS_SA_MARKET(this.props.user.market) && (
                <div className={styles.lineTextHeader}>
                  <div>
                    <span className="lineNumber">1 </span>
                    <span className="lineText">
                      {" "}
                      {this.props.t("OnboardingWelcome.stepOne")}
                    </span>
                  </div>
                  pr
                  <div>
                    <span className="lineNumber">2 </span>
                    <span className="lineText">
                      {" "}
                      {this.props.t("OnboardingWelcome.stepTwo")}
                      <span className={styles.normalFontWeight}>
                        {" "}
                        {this.props.t("OnboardingWelcome.stepTwoSub")}{" "}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="lineNumber">3 </span>
                    <span className="lineText">
                      {" "}
                      {this.props.t("OnboardingWelcome.stepThree")}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          noRender={() => {
            return (
              <div className={styles.center}>
                <span className="lineText">
                  {" "}
                  {this.props.t("OnboardingWelcome.acceptTerms")}
                </span>
              </div>
            );
          }}
        />
        <div className="confirmButton">
          <Button
            className="quickStart"
            type="button"
            onClick={this.props.onDecline}
            id="start-action-onboard-btn"
          >
            {this.props.t("OnboardingWelcome.getStarted")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(TermsDeclined);
