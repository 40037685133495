import React, { Component } from "react";
import styles from "../translations/Translation.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import { Grid, TextField } from "@material-ui/core";
import TranslationsClient from "./TranslationsClient";
import { NOTNULL_OR_NOTUNDEFINED } from "../../utils/EmpUtil";

class TranslationSpecific extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: {},
      oldLanguage: {},
      isEditable: false,
    };
    this.lan = this.props.currentLan;
  }

  componentDidMount() {
    this.setState({ language: this.props.language });
  }

  handleChange = (event) => {
    const updatedLanguage = { ...this.state.language };
    updatedLanguage[this.lan] = event.target.value;
    this.setState({ language: updatedLanguage });
  };

  edit = () => {
    const current = this.state.isEditable;
    this.setState({ isEditable: !current, oldLanguage: this.state.language });
  };

  cancel = () => {
    const current = this.state.isEditable;
    this.setState({ isEditable: !current, language: this.state.oldLanguage });
  };

  publish = () => {
    const current = this.state.isEditable;
    TranslationsClient.updateCurrentTranslations(
      this.lan,
      this.props.moduleKey,
      this.state.language.key,
      this.state.language[this.lan],
      this.props.userDetails.market
    );
    this.setState({ isEditable: !current });
  };

  render() {
    return NOTNULL_OR_NOTUNDEFINED(this.state.language[this.lan]) &&
      !this.state.language[this.lan].startsWith("{") ? (
      <div>
        <Grid container className={styles.lan}>
          <Grid item md={3} className={styles.lanDetails}>
            {this.state.language.key}
          </Grid>
          <Grid item md={3} className={styles.lanDetails}>
            {this.state.language.en}
          </Grid>
          <Grid item md={3} className={styles.lanDetails}>
            {this.state.isEditable ? (
              <TextField
                className={styles.inputFieldspecific}
                style={{ padding: "5px" }}
                type="text"
                variant="outlined"
                name="topicBox"
                multiline
                value={this.state.language[this.lan]}
                onChange={this.handleChange}
              />
            ) : (
              <Grid item md={3} className={styles.lanDetails}>
                {this.state.language[this.lan]}
              </Grid>
            )}
          </Grid>
          {this.state.isEditable ? (
            <Grid item md={3} className={styles.lanUpdate}>
              <DoneIcon
                fontSize="small"
                style={{ color: "green" }}
                className={styles.button_hover}
                onClick={this.publish}
              />
              <ClearIcon
                fontSize="small"
                style={{ color: "red" }}
                className={styles.button_hover}
                onClick={this.cancel}
              />
            </Grid>
          ) : (
            <Grid item md={3} className={styles.lanUpdate}>
              <EditIcon
                fontSize="small"
                color="primary"
                variant="outlined"
                className={styles.button_hover}
                onClick={this.edit}
              />
            </Grid>
          )}
        </Grid>
      </div>
    ) : null;
  }
}

export default TranslationSpecific;
