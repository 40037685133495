const helpContactInfo = {
  GBR: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  DEU: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: false,
    contactEmail: "fordrls@ford.com",
  },
  NOR: {
    contactSubHeader: "Help.subheader2",
    displayContact: false,
    displayLink: false,
    displayPhone: false,
    contactEmail: "None",
  },
  FRA: {
    contactSubHeader: "Help.subheader3",
    displayContact: false,
    displayLink: true,
    displayPhone: false,
    contactLink:
      "https://www.eufmcdealer.dealerconnection.com/sites/fra/doc/Pages/New-Query.aspx",
    contactEmail: "None",
  },
  NLD: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: false,
    contactEmail: "helpbnl@ford.com",
  },
  CHE: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: false,
    contactEmail: "ecommch@ford.com",
  },
  ITA: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: false,
    contactEmail: "empita@ford.com",
  },
  CAN: {
    contactSubHeader: "Help.subheader",
    additionalAddress: true,
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.caAddressTitle",
    address1: "Help.caAddress1",
    address2: "Help.caAddress2",
    phoneNumber: "1-800-467-8925",
    contactEmail: "ics@ford.com",
  },
  AUS: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  NZL: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  THA: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  ZAF: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  PHL: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  VNM: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
  },
  ARG: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
    contactEmail: "Help.contactEmail",
  },
  BRA: {
    contactSubHeader: "Help.subheader",
    displayContact: true,
    displayLink: false,
    displayPhone: true,
    addressTitle: "Help.addressTitle",
    contactEmail: "Help.contactEmail",
  },
};

export { helpContactInfo };
