import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import FileEncoder from "../../utils/FileEncoder";
import { BASE_URL, IS_SA_MARKET } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

const fileEncoder = new FileEncoder();
let file = undefined;
let reservations = undefined;

class NscOnboardingClient {
  static baseUrl = BASE_URL;

  static async onBoard(user, data) {
    if (data.file) {
      if (IS_SA_MARKET(user.market)) {
        file = data.file;
        return await this.completeOnboarding(user, null);
      } else {
        file = data.file;
        return Promise.resolve();
      }
    }
    if (data.reservations) {
      reservations = data.reservations;
      return Promise.resolve();
    }
    if (data.paymentThreshold) {
      return await this.completeOnboarding(user, reservations);
    }
  }

  static completeOnboarding(user, deposits) {
    return fileEncoder
      .encode(file)
      .then((encodedFile) => {
        const url = `${this.baseUrl}/${user.generateResourcePath()}/onboarding`;
        return axios.post(
          url,
          {
            termsAndConditions: { file: encodedFile },
            nscUserId: user.userId,
            deposits: deposits,
          },
          getHeaders(user.token)
        );
      })
      .then(() => Promise.resolve("go to dashboard"))
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static isOnboarded(user) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/is-onboarded`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default NscOnboardingClient;
