import React from "react";
import styles from "../OnboardingWelcome.module.scss";
import { withTranslation } from "react-i18next";

const SubDealerList = (props) => {
  return (
    <div
      data-testid={props.dealer.dealerId}
      className={
        props.dealer.isOnboarded ? styles.onBoarded : styles.notOnBoarded
      }
    >
      {props.dealer.name} ({props.dealer.dealerId}){" "}
      {props.dealer.isOnboarded
        ? props.t("OnboardingWelcome.onboardingComplete")
        : ""}
      <br />
    </div>
  );
};

export default withTranslation("emp")(SubDealerList);
