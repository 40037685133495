import React, { Component } from "react";
import styles from "./Reporting.module.scss";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import SubNav from "../../shared/subnav/SubNav";
import { base64FileDownload, fileDownload } from "../../utils/FileDownloadUtil";
import { logMsg } from "../../common/Logger";
import DownloadButton from "../../shared/buttons/DownloadButton";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import {
  CA_BRONCO_CATALOG_ID,
  CA_BRONCO_SPORT_CATALOG_ID,
  CA_F150_CATALOG_ID,
} from "../../common/Constants";
import NscReportingClient from "../../shared/clients/NscReportingClient";

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.commonReport = [
      {
        name: "Reporting.report1Title",
        key: "report1",
        downloadLink: this.downloadDealerActivationReport.bind(this),
      },
    ];
    this.reports = this.getReports();
  }

  getReports = () => {
    if (this.props.user.market.toLowerCase() === "can") {
      return [
        ...this.commonReport,
        {
          name: "Reporting.report4Title",
          downLoadName: "Reporting.downloadDealerReportBronco",
          key: "report2",
          catalog: CA_BRONCO_CATALOG_ID,
          downloadLink: this.downloadDealerReservationSummary.bind(this),
        },
        {
          name: "Reporting.report5Title",
          downLoadName: "Reporting.downloadDealerReportBroncoSport",
          key: "report3",
          catalog: CA_BRONCO_SPORT_CATALOG_ID,
          downloadLink: this.downloadDealerReservationSummary.bind(this),
        },
        {
          name: "Reporting.report7Title",
          downLoadName: "Reporting.downloadDealerReportF150",
          key: "report4",
          catalog: CA_F150_CATALOG_ID,
          downloadLink: this.downloadDealerReservationSummary.bind(this),
        },
      ];
    } else {
      return [
        ...this.commonReport,
        // FOR IMG/SA MARKET - ENABLE THIS WHEN WE MAKE orders report VISIBLE FOR NSC UNDER REPORTING TAB
        // {
        //   name: "Reporting.report2Title",
        //   downLoadName: "Reporting.downloadDealerReport",
        //   key: "report2",
        //   downloadLink: this.downloadDealerReservationSummary.bind(this),
        // },

        {
          name: "Reporting.nscInvPricingReportTitle",
          downLoadName: "Reporting.nscInvPricingReportTitle",
          key: "report8",
          downloadLink: this.downloadInventoryPricingReport.bind(this),
        },
        {
          name: "dealerReporting.report6Name",
          downLoadName: "dealerReporting.report6Name",
          key: "report6",
          downloadLink: this.downloadInventoryPricingHistoryReport.bind(this),
        },
      ];
    }
  };

  downloadDealerActivationReport = (report) => {
    this.props.hideOrShow(true);
    NscReportingClient.downloadDealerActivationReportInCsv(this.props.user)
      .then((res) => {
        const filename = this.props.t(report.name) + ".csv";
        const BOM = "\uFEFF";
        const response = BOM + res;
        fileDownload(
          decodeURIComponent(response),
          "text/csv;charset=utf-8;",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadDealerReservationSummary = (report) => {
    this.props.hideOrShow(true);
    NscReportingClient.downloadDealerReservationSummary(
      this.props.user,
      this.props.user.market,
      report.catalog
    )
      .then((response) => {
        const filename = this.props.t(report.downLoadName) + ".csv";
        fileDownload(
          decodeURIComponent(response),
          "text/csv;charset=utf-8;",
          filename,
          "reservation-reports"
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadPricingReport = (report) => {
    this.props.hideOrShow(true);
    NscReportingClient.downloadPricingReport(this.props.user)
      .then((response) => {
        const filename = this.props.t(report.downLoadName) + ".csv";
        fileDownload(
          decodeURIComponent(response),
          "text/csv;charset=utf-8;",
          filename,
          "pricing-report"
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadInventoryPricingReport = () => {
    this.props.hideOrShow(true);

    const nscColumnHeaders = this.props.t(
      "Reporting.nscInventoryPricingReportHeaders"
    );

    const data = {
      columnHeaders: nscColumnHeaders,
    };

    NscReportingClient.downloadInventoryPricingReport(this.props.user, data)
      .then((res) => {
        const filename =
          this.props.t("Reporting.nscInvPricingReportTitle") +
          " - " +
          this.getTodayDateDDMMYYYY() +
          ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadInventoryPricingHistoryReport = () => {
    this.props.hideOrShow(true);
    const nscColumnHeaders = this.props.t(
      "Reporting.nscInventoryPricingReportHeaders"
    );

    const data = {
      columnHeaders: nscColumnHeaders,
    };

    NscReportingClient.downloadInventoryPricingHistoryReport(
      this.props.user,
      data
    )
      .then((res) => {
        const filename =
          this.props.t("Reporting.nscInventoryHistoryReportTitle") +
          " - " +
          this.getTodayDateDDMMYYYY() +
          ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  getTodayDateDDMMYYYY() {
    const today = new Date();
    return `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
  }

  render() {
    return (
      <div id="reporting">
        <section className="pageWrapper">
          <div id="reportingHeader">
            <SubNav
              dashboard={false}
              title={this.props.t("Reporting.subnav1")}
              {...this.props}
            />
          </div>
          <div className="pagePaddingLeft pagePaddingTop">
            <InfoIconWithMessage message={this.props.t("Reporting.bodyCopy")} />
          </div>
          <div>
            <Grid container className={styles.dataContainer}>
              <Grid
                item
                className={`${styles.headerContainer} ${styles.rowDiv} pagePaddingLeft pagePaddingRight`}
              >
                <div>{this.props.t("Reporting.columnTitle1")}</div>
              </Grid>
              {this.reports.map((report) => (
                <React.Fragment key={report.key}>
                  <Grid
                    container
                    className={`${styles.rowDiv} pagePaddingLeft pagePaddingRight`}
                  >
                    <Grid item md={10}>
                      <div className={styles.colValues}>
                        {this.props.t(report.name)}
                      </div>
                    </Grid>
                    <Grid item md={2} className={`${styles.end}`}>
                      <DownloadButton
                        // eslint-disable-next-line
                        download={report.downloadLink.bind(this, report)}
                        text={this.props.t("Reporting.download")}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("emp")(Reporting);
