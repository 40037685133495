import UploadTermsConditions from "../../components/uploadTermsConditions/UploadTermsConditions";
import AcceptTermsConditions from "../../components/acceptterms/AcceptTermsConditions";
import EmpOnBoarding from "../../components/quicksetup/emponboarding/OnboardingWelcome";
import withIdleTimer from "../../common/WithIdleTimer";

const ProgressBarTabs = () => {
  return {
    onboardingNsc: [
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(UploadTermsConditions),
        saveBtn: "uploadFile",
        actionText: "ActionFooter.confirm",
      },
    ],
    onboardingSANsc: [
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(UploadTermsConditions),
        saveBtn: "uploadFile",
        actionText: "ActionFooter.confirm",
      },
    ],
    onboardingDealer: [
      {
        key: 0,
        name: "ProgressBar.welcome",
        status: "active",
        stepComponent: withIdleTimer(EmpOnBoarding),
        saveBtn: "welcome",
        actionText: "ActionFooter.confirm",
      },
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(AcceptTermsConditions),
        saveBtn: "acceptTermsAndConditions",
        actionText: "ActionFooter.confirm",
      },
    ],
    onboardingSADealer: [
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(AcceptTermsConditions),
        saveBtn: "acceptTermsAndConditions",
        actionText: "ActionFooter.confirm",
      },
    ],
  };
};

export default ProgressBarTabs;
