import React, { Component } from "react";
import TranslationTable from "./TranslationTable";
import { logMsg } from "../../common/Logger";
import NewComponentTranslation from "./NewComponentTranslation";
import { Link, MenuItem, withStyles } from "@material-ui/core";
import styles from "./Translation.module.scss";
import TranslationsClient from "./TranslationsClient";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardArrowDown } from "@material-ui/icons";
import subNavStyles from "../../shared/subnav/subNavStyles";
import Select from "@material-ui/core/Select";
import {
  LANCODES,
  MARKET_LANGUAGES,
  TRANSLATION_TOOL_REGIONS,
} from "../../common/Constants";

const PickUpStyledSelect = withStyles({
  select: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    width: "calc(100% - 34px)",
    boxSizing: "border-box",
    fontSize: 14,
    color: "#102b4e",
    lineHeight: 1.57,
    letterSpacing: 1.5,
    fontFamily: "FordAntenna-Regular, sans-serif",
    "&:focus": {
      background: "transparent",
    },
  },
})(Select);

const PickUpStyledMenuItem = withStyles({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
    fontSize: 14,
    color: "#102b4e",
    lineHeight: 1.57,
    letterSpacing: 1.5,
    fontFamily: "FordAntenna-Regular, sans-serif",
  },
})(MenuItem);

const PickUpFormControl = withStyles({
  root: {
    width: 150,
  },
})(FormControl);

class TranslationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataLoaded: false,
      addNewComponent: false,
      disableAddComponent: true,
      showNewComponentTranslation: true,
      translation: [],
      currentLan: null,
      fetchFailed: false,
    };
    this.marketLan = null;
  }

  componentDidMount() {
    this.getLangDropDown();
    this.getTranslations(
      this.props.localizedInfo.defaultLanguage.isocode.substr(0, 2)
    );
  }

  onClickAddComponent = () => {
    this.setState({ disableAddComponent: false });
    this.setState({ addNewComponent: true, showNewComponentTranslation: true });
  };

  handleInputChange = (event) => {
    if (
      event.target.value !== this.state.currentLan &&
      event.target.value !== undefined
    ) {
      this.setState({ currentLan: event.target.value });
      this.getTranslations(event.target.value);
    }
  };

  getTranslations = (lanCode) => {
    this.props.hideOrShow(true);
    this.setState({ isDataLoaded: false });
    TranslationsClient.getTranslationsLanguage(
      lanCode,
      this.props.userDetails.market
    )
      .then((response) => {
        const translation = [];
        this.setState({
          translation: Object.assign({ ...translation }, { ...response }),
        });
      })
      .catch((error) => {
        logMsg("the error is: ", error);
        this.setState({
          fetchFailed: true,
        });
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ isDataLoaded: true });
      });
  };

  getLangDropDown = () => {
    let currentLan = null;
    this.marketLan = MARKET_LANGUAGES[this.props.user.market];
    currentLan = this.props.localizedInfo.defaultLanguage.isocode.substr(0, 2);
    this.setState({ currentLan });
  };

  addTranslationComponent = (updatedLanguage) => {
    this.props.hideOrShow(true);
    TranslationsClient.postAddComponentTranslations(
      updatedLanguage,
      this.getMarketsForNewTranslations()
    )
      .then(() => {
        this.getTranslations(this.state.currentLan);
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          disableAddComponent: true,
          showNewComponentTranslation: false,
        });
      });
  };

  getMarketsForNewTranslations = () => {
    for (const key in TRANSLATION_TOOL_REGIONS) {
      if (
        TRANSLATION_TOOL_REGIONS[key].includes(this.props.userDetails.market)
      ) {
        return TRANSLATION_TOOL_REGIONS[key];
      }
    }
    return null;
  };

  showNewComponentTranslation() {
    const current = this.state.showNewComponentTranslation;
    this.setState({ showNewComponentTranslation: !current });
  }

  cancelTranslation = () => {
    this.setState({ disableAddComponent: true });
    this.setState({
      addNewComponent: false,
      showNewComponentTranslation: false,
    });
  };

  render() {
    if (this.state.fetchFailed) {
      return (
        <div className={styles.errorText}>
          <h2>{this.props.t("FetchError.errorText")}</h2>
        </div>
      );
    } else {
      return (
        <div>
          {this.state.isDataLoaded && (
            <div>
              {this.marketLan && (
                <div className={styles.dropdownDiv}>
                  <span className={styles.inputLabel}>
                    {this.props.t("Translations.translationLanguage")}
                  </span>

                  <PickUpFormControl fullWidth={false}>
                    <PickUpStyledSelect
                      IconComponent={() => (
                        <KeyboardArrowDown style={subNavStyles.icon} />
                      )}
                      name="langcode"
                      id="pickUplang"
                      data-testid="langDropDown"
                      className={styles.dropdown}
                      value={this.state.currentLan}
                      onClick={this.handleInputChange}
                    >
                      {this.marketLan &&
                        this.marketLan.map((state) => {
                          return (
                            <PickUpStyledMenuItem key={state} value={state}>
                              <div>{LANCODES[state]}</div>
                            </PickUpStyledMenuItem>
                          );
                        })}
                    </PickUpStyledSelect>
                  </PickUpFormControl>
                </div>
              )}
              {Object.keys(this.state.translation.translations).length !== 0 &&
                Object.keys(this.state.translation.translations).map((id) => {
                  return (
                    <TranslationTable
                      {...this.props}
                      currentLan={this.state.currentLan}
                      languageSelected={this.props.localizedInfo.defaultLanguage.isocode.substr(
                        0,
                        2
                      )}
                      translations={this.state.translation.translations[id]}
                      key={this.state.translation.translations[id].moduleKey}
                      getTranslations={this.getTranslations}
                      getMarketsForNewTranslations={
                        this.getMarketsForNewTranslations
                      }
                    />
                  );
                })}
            </div>
          )}
          <br />

          <div>
            <div style={{ position: "center" }}>
              {this.state.disableAddComponent && (
                <Link
                  className={styles.newTranslation}
                  style={{ color: "#102b4e" }}
                  variant="contained"
                  onClick={this.onClickAddComponent}
                >
                  {this.props.t("Translations.addNewComponent")}
                </Link>
              )}
            </div>
            {this.state.addNewComponent && (
              <NewComponentTranslation
                {...this.props}
                showNewComponentTranslation={this.showNewComponentTranslation}
                showNewComponentTranslationvalue={
                  this.state.showNewComponentTranslation
                }
                currentLan={this.state.currentLan}
                languageSelected={this.props.localizedInfo.defaultLanguage.isocode.substr(
                  0,
                  2
                )}
                addTranslationComponent={this.addTranslationComponent}
                cancelTranslation={this.cancelTranslation}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

export default TranslationDetails;
