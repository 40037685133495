/**
 * This method is used for sorting the given data.
 * @param sortByCol column name which needs to be sorted from the given array
 * @param sortDirection ASC or DSC
 * @param sortType 'alphanumeric' or 'date'
 * @param data which needs to be sorted
 * @param callback function which executes after sorting is completed
 * @returns {*} sorted data from callback function
 * @constructor
 */
const SortData = (sortByCol, sortDirection, sortType, data, callback) => {
  const copyData = JSON.parse(JSON.stringify(data));

  if (sortType === "alphanumeric") {
    //If its null or DSC it will be 1 or else -1
    const isReversed =
      sortDirection === null || sortDirection === "DSC" ? 1 : -1;

    copyData.sort(
      (a, b) => isReversed * (a[sortByCol] > b[sortByCol] ? -1 : 1)
    );
  } else if (sortType === "date") {
    //TODO
  }
  return callback(copyData);
};

export const SortOrders = (
  sortByCol,
  sortDirection,
  data,
  canadianDealer = false,
  dealerCommonId = null,
  callback
) => {
  const copyData = JSON.parse(JSON.stringify(data));

  const isReversed = sortDirection === null || sortDirection === "ASC" ? 1 : -1;

  if (canadianDealer) {
    const dealerOrders = copyData[dealerCommonId].reservations;

    dealerOrders.sort(
      (a, b) =>
        isReversed * (getItem(a, sortByCol) > getItem(b, sortByCol) ? -1 : 1)
    );

    copyData[dealerCommonId] = {
      reservations: dealerOrders,
      sortDirection: isReversed === 1 ? "DSC" : "ASC",
      sortKey: sortByCol,
    };
  } else {
    copyData.sort(
      (a, b) =>
        isReversed * (getItem(a, sortByCol) > getItem(b, sortByCol) ? -1 : 1)
    );
    return callback(
      copyData,
      (sortDirection = isReversed === 1 ? "DSC" : "ASC"),
      sortByCol
    );
  }

  return callback(copyData);
};

function getItem(order, type) {
  if (type === "status") {
    return order["purchaseRequestStatus"] ? order["purchaseRequestStatus"] : "";
  }
  if (type === "date") {
    return order["purchaseRequestDate"]
      ? new Date(order["purchaseRequestDate"].split("/").reverse())
      : new Date();
  }
  if (type === "specSummary") {
    let specString = "";
    if (order["nameplate"]) {
      specString = order["nameplate"];
    }
    if (order["nameplate"] && order["specSummary"]) {
      specString = specString + " / ";
    }
    if (order["specSummary"]) {
      const specList = order["specSummary"].filter(Boolean).join(" / ");
      specString = specString + specList;
    }
    return specString;
  }
}

export const SortSubGroupData = (
  sortByCol,
  sortDirection,
  sortType,
  data,
  callback
) => {
  const copyData = JSON.parse(JSON.stringify(data));
  if (sortType === "alphanumeric") {
    //If its null or DSC it will be 1 or else -1
    const isReversed =
      sortDirection === null || sortDirection === "DSC" ? 1 : -1;

    copyData.forEach((group) => {
      group.namePlateCategorySubGroup.sort(
        (a, b) => isReversed * (a[sortByCol] > b[sortByCol] ? -1 : 1)
      );
    });
  }
  return callback(copyData);
};

export default SortData;
