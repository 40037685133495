export const TERMS_AND_CONDITIONS = "TERMS_CONDITIONS";

export const PRICING = "PRICING";

export const RESERVATION_DEPOSIT = "RESERVATION_DEPOSIT";

export const STORE_ID = "STORE_ID";

export const ACCESSORIES_PRICING = "ACCESSORIES_PRICING";

export const DELIVERY_BANDS = "DELIVERY_BANDS";

export const VARIABLE_MARKETING = "VM";

export const OPT_IN = "OPT_IN";

export const GPIS_PRICING = "GPIS_PRICING";

export const NEW_VEHICLE_LINE = "NEW_VEHICLE_LINE";

export const ALD_PRICING = "ALD_PRICING";

export const PAYMENT_THRESHOLD = "PAYMENT_THRESHOLD";

export const CUPID_ID = "CUPID_ID";

export const LEAD_TIMES = "LEAD_TIMES";

export const WALLBOX = "WALLBOX";

export const DEALER_FEES = "FEES";

export const CUSTOMER_CONTRACT = "CUSTOMER_CONTRACT";

export const CA_MACHE_CATALOG_ID = "WANAC-CGW-2021-CX727";

export const US_MACHE_CATALOG_ID = "WANAB-CGW-2021-CX727";

export const CA_BRONCO_CATALOG_ID = "WANAC-TG1-2021-Bronco";

export const US_BRONCO_CATALOG_ID = "WANAB-TG1-2021-Bronco";

export const CA_BRONCO_SPORT_CATALOG_ID = "WANAC-CHD-2021-BroncoSport";

export const US_BRONCO_SPORT_CATALOG_ID = "WANAB-CHD-2021-BroncoSport";

export const CA_F150_CATALOG_ID = "WANAC-TFD-2022-F-150";

export const US_F150_CATALOG_ID = "WANAB-TFD-2022-F-150";

export const USA_MARKET_CODE = "USA";

export const ENGLISH = "en";

export const USA_ENGLISH = "en_US";

export const CANADA_ENGLISH = "en_CA";

export const CANADA_FRENCH = "fr_CA";

export const THAILAND_ENGLISH = "en_THA";

export const THAILAND_THAI = "th_THA";

export const PAGE = "page";

export const BRAND = "brand";

export const ACCESSORIES_KEY = "accessories";

export const SALESCODE_KEY = "sales.code";

export const ADFS_TOKEN = "adfs.token";

export const MARKET = "market";

export const CASH = "cash";

export const FINANCE = "finance";

export const LEASE = "lease";

export const FORDOPTION = "fordoption";

export const OFFLINE_LEASE = "offline_lease";

export const OFFLINELEASE = "offline lease";

export const OFFLINE_FINANCE = "offline_finance";

export const OFFLINEFINANCE = "offline finance";

export const FINANCE_PRODUCT_TYPE = {
  FINANCE: "finance",
  LEASE: "lease",
  FORD_OPTIONS: "ford options",
  CASH: "cash",
  THIRD_PARTY: "finance",
  BUSINESS_CONTRACT_HIRE: "lease",
  OTHER: "other financing options",
};

export const THIRD_PARTY_FINANCING = "Third Party Financing";

export const DELIVERY_FEE = "deliveryFee";

export const FIRST_REG_FEE = "firstRegFee";

export const CITY_FEE = "cityFee";

export const DEALER_HANDLING_FEE = "handlingFee";

export const ADDITIONAL_FEES = "additionalFees";

export const NUMBER_PLATE = "numberPlates";

export const DELIVERY = "delivery";

export const NA_DEFAULT_CURRENCY = "$";

export const CANCELLED = "CANCELLED";

export const ACCEPTED = "ACCEPTED";

export const SUBMITTED = "SUBMITTED";

export const OFFLINEDOCSIGN = "offlineDocsign";

export const PARTIALOFFLINE = "partialOfflinePriceUpdate";

export const FINANCED = "FINANCED";

export const LEASED = "LEASED";

export const OWNED = "OWNED";

export const WALLBOXACCEPTANCE = "wallboxAcceptance";

export const ACCEPT_AND_SUBMIT = "acceptAndSubmit";
export const ACCEPT_AND_SUBMIT_DROP_OFF_CONFIRM =
  "acceptAndSubmitDropOffConfirm";
export const ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM = "acceptAndSubmitPickUpConfirm";
export const ACCEPT_AND_KEEP = "acceptAndKeep";
export const ACCEPT_AND_KEEP_CONFIRM = "acceptAndKeepConfirm";
export const REJECT = "reject";
export const REJECT_CONFIRM = "rejectConfirm";
export const DECISION = "decision";
export const ACCEPT_KEEP_DEALER_ACTION = "tradeinacceptandkeep";
export const ACCEPT_AUCTION_DEALER_ACTION = "tradeinacceptandauction";
export const REJECT_DEALER_ACTION = "tradeinrejected";
export const MINORMAX_REDEMPTION_MILEAGE = 250;

export const INCLUDED = "included";
export const SELECTED = "selected";

export const ACCESSORIES_VERSION = {
  GBR: 1,
  FRA: 1,
  NOR: 3,
  NLD: 1,
  CHE: 1,
  DEU: 1,
  ESP: 3,
  ITA: 3,
  AUT: 3,
  BEL: 3,
  FIN: 3,
  DNK: 3,
};
export const FINANCE_OPTIONS = {
  APPROVED: "APPROVED",
  SIGNING: "SIGNING",
  SIGNED: "SIGNED",
  FINANCE_REFERRED: "FINANCE_REFERRED",
  CANCELLED: "CANCELLED",
  WETSIGN_OFFLINE: "WETSIGN_OFFLINE",
  FINANCE_CONTRACT: "contractwetsigned",
  CUSTOMER_DOWN_PAYMENT: "downpaymentpaidoffline",
  FINANCE_PAYMENT_CONFIRMED: "finalpaymentconfirmed",
  ONLINE_APPROVED_MARKETS: ["GBR", "FRA", "NOR"],
  GBR: [
    {
      key: "registrationNumber",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "uploadDealerInvoice",
      previousKey: "registrationNumber",
      hasPrevious: true,
    },
    {
      key: "financeContract",
      previousKey: "uploadDealerInvoice",
      hasPrevious: true,
    },
    {
      key: "customerDownPayment",
      previousKey: "financeContract",
      hasPrevious: true,
    },
    {
      key: "financePayment",
      previousKey: "customerDownPayment",
      hasPrevious: true,
    },
  ],
  FRA: [
    {
      key: "uploadDealerInvoice",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "uploadDeliveryCertificate",
      previousKey: "uploadDealerInvoice",
      hasPrevious: true,
    },
    {
      key: "financeContract",
      previousKey: "uploadDeliveryCertificate",
      hasPrevious: true,
    },
    {
      key: "customerDownPayment",
      previousKey: "financeContract",
      hasPrevious: true,
    },
    {
      key: "financePayment",
      previousKey: "customerDownPayment",
      hasPrevious: true,
    },
  ],
  NOR: [
    {
      key: "financeContract",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "customerDownPayment",
      previousKey: "financeContract",
      hasPrevious: true,
    },
    {
      key: "financePayment",
      previousKey: "customerDownPayment",
      hasPrevious: true,
    },
  ],
};

export const CASH_OPTIONS = {
  ACCEPT_ORDER: "accepttheorder",
  CUSTOMER_DOWN_PAYMENT: "offlineeudownpayment",
  FINAL_PAYMENT_CONFIRMED: "finalPaymentConfirmed",
  FINANCE_PAYMENT_CONFIRMED: "finalpaymentconfirmed",
  ONLINE_APPROVED_MARKETS: ["GBR", "FRA", "NOR", "CHE", "NLD", "DEU"],
  GBR: [
    {
      key: "acceptTheOrder",
      previousKey: false,
      hasPrevious: false,
    },
    {
      key: "openPaymentWindow",
      previousKey: "acceptTheOrder",
      hasPrevious: true,
    },
    {
      key: "customerFullPayment",
      previousKey: "openPaymentWindow",
      hasPrevious: true,
    },
  ],
  FRA: [
    {
      key: "acceptTheOrder",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "openPaymentWindow",
      previousKey: "acceptTheOrder",
      hasPrevious: true,
    },
    {
      key: "customerFullPayment",
      previousKey: "openPaymentWindow",
      hasPrevious: true,
    },
  ],
  NOR: [
    {
      key: "acceptTheOrder",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "openPaymentWindow",
      previousKey: "acceptTheOrder",
      hasPrevious: true,
    },
    {
      key: "customerFullPayment",
      previousKey: "openPaymentWindow",
      hasPrevious: true,
    },
  ],
  CHE: [
    {
      key: "acceptTheOrder",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "openPaymentWindow",
      previousKey: "acceptTheOrder",
      hasPrevious: true,
    },
    {
      key: "customerFullPayment",
      previousKey: "openPaymentWindow",
      hasPrevious: true,
    },
  ],
  DEU: [
    {
      key: "acceptTheOrder",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "openPaymentWindow",
      previousKey: "acceptTheOrder",
      hasPrevious: true,
    },
    {
      key: "customerFullPayment",
      previousKey: "openPaymentWindow",
      hasPrevious: true,
    },
  ],
  NLD: [
    {
      key: "acceptTheOrder",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "openPaymentWindow",
      previousKey: "acceptTheOrder",
      hasPrevious: true,
    },
    {
      key: "customerFullPayment",
      previousKey: "openPaymentWindow",
      hasPrevious: true,
    },
  ],
};

export const LEASE_OPTIONS = {
  COMPLETED: "COMPLETED",
  LEASE_CONTRACT: "contractwetsigned",
  LEASE_OFFLINE_BALANCE_PAYMENT: "offlinebalancepayment",
  LEASE_PAYMENT_CONFIRMED: "finalpaymentconfirmed",
  OFFLINE_APPROVED_MARKETS: ["GBR", "FRA", "DEU", "NLD"],
  ONLINE_APPROVED_MARKETS: ["FRA", "DEU", "NLD"],
  GBR: [
    {
      key: "leaseContractSigned",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "registrationNumber",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "leaseContractSignedOnline",
      previousKey: "registrationNumber",
      hasPrevious: true,
    },
    {
      key: "leasePayBalence",
      previousKey: "leaseContractSignedOnline",
      hasPrevious: true,
    },
    {
      key: "dealerInvoice",
      previousKey: "leasePayBalence",
      hasPrevious: true,
    },
    {
      key: "certificateRegistration",
      previousKey: "dealerInvoice",
      hasPrevious: true,
    },
    {
      key: "deliveryNotes",
      previousKey: "certificateRegistration",
      hasPrevious: true,
    },
    {
      key: "leasePayment",
      previousKey: "deliveryNotes",
      hasPrevious: true,
    },
  ],
  FRA: [
    {
      key: "leaseContractSigned",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "leasePayBalence",
      previousKey: "leaseContractSigned",
      hasPrevious: true,
    },
    {
      key: "leasePayment",
      previousKey: "leasePayBalence",
      hasPrevious: true,
    },
  ],
  NLD: [
    {
      key: "leaseContractSigned",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "leasePayBalence",
      previousKey: "leaseContractSigned",
      hasPrevious: true,
    },
    {
      key: "leasePayment",
      previousKey: "leasePayBalence",
      hasPrevious: true,
    },
  ],
  DEU: [
    {
      key: "leaseContractSigned",
      previousKey: null,
      hasPrevious: false,
    },
    {
      key: "leasePayBalence",
      previousKey: "leaseContractSigned",
      hasPrevious: true,
    },
    {
      key: "leasePayment",
      previousKey: "leasePayBalence",
      hasPrevious: true,
    },
  ],
};
// add new language for img markets eg. en_AU
export const LANGUAGE_MARKET_MAP = {
  GBR: "en_GB",
  FRA: "fr",
  NOR: "no",
  NLD: "nl",
  CHE: "de_CH",
  DEU: "de",
  ESP: "es_ES",
  ITA: "it_IT",
  AUT: "de_AT",
  BEL: "fr_BE",
  FIN: "fi_FI",
  DNK: "da_DK",
  USA: "en_US",
  CAN: "en_CA",
  AUS: "en_AU",
  NZL: "en_NZ",
  ZAF: "en_ZA",
  THA: "th_TH",
  VNM: "vi_VN",
  PHL: "ph_PH",
  BRA: "pt_BR",
  ARG: "es_AR",
};

export const FEES_MAP = {
  GBR: [
    {
      key: "roadFundLicense",
      name: "DealerFees.roadFundLicense",
      isEditable: false,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.firstRegFee",
      isEditable: false,
    },
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  ESP: [
    {
      key: "cityFee",
      name: "DealerFees.cityFee",
      isEditable: true,
    },
    {
      key: "handlingFee",
      name: "DealerFees.handlingFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  ITA: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  AUT: [
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  BEL: [
    {
      key: "handlingFee",
      name: "DealerFees.handlingFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  FIN: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  DNK: [
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: false,
    },
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: false,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  DEU: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  FRA: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  NOR: [
    {
      key: "deliveryFee",
      name: "DealerFees.deliveryFee",
      isEditable: true,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "scrapFee",
      name: "DealerFees.scrapFee",
      isEditable: false,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  NLD: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFeeInclReg",
      isEditable: false,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  CHE: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFeeInclReg",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
};

export const MUSTANG_MACH_E = "Mustang Mach-E";

export const SRP_PRICE_TYPE = "SRP";

export const INCLUDED_FEATURE = "I";

export const DEFAULT_FEATURE = "D";

export const SELECTED_FEATURE = "S";

export const CHECURRENCY = "CHF";

export const PURCHASE_REQUEST_ACCEPTED = "purchaserequestaccepted";
export const PURCHASE_REQUEST_CANCELLED = "purchaserequestcancelled";

export const PURCHASE_DEPOSIT_STATUS = {
  ACCEPTED: "purchasedepositaccepted",
  DECLINED: "purchasedepositacceptdeclined",
  FAILED: "purchasedepositacceptfailed",
  REJECTED: "purchasedepositrejected",
  REFUNDED: "purchasedepositrefund",
  CREATED: "purchasedepositcreated",
  ENABLE: "enable",
};

export const VEHICLE_HOLD_DEPOSIT_STATUS = {
  ACCEPTED: "vehicleholddepositaccepted",
  DECLINED: "vehicleholddepositacceptdeclined",
  FAILED: "vehicleholddepositacceptfailed",
  REJECTED: "vehicleholddepositrejected",
  REFUNDED: "vehicleholddepositrefund",
};

export const PURCHASE_REFUND_STATUS = {
  ACCEPTED: "purchasedepositrefund",
  DECLINED: "purchasedepositrefunddeclined",
  FAILED: "purchasedepositrefundfailed",
};

export const VEHICLE_HOLD_REFUND_STATUS = {
  ACCEPTED: "vehicleholddepositrefund",
  DECLINED: "vehicleholddepositrefunddeclined",
  FAILED: "vehicleholddepositrefundfailed",
};

export const ORDER_DEPOSIT_STATUS = {
  REFUNDED: "refunded",
  FAILED: "failed",
  DENIED: "denied",
  DECLINED: "declined",
};

export const PURCHASE_DEPOSIT_ACCEPT_ACTION = "purchasedepositaccepted";

export const PURCHASE_DEPOSIT_REJECT_ACTION = "purchasedepositrejected";

export const PURCHASE_DEPOSIT_REFUND_ACTION = "purchasedepositrefund";

export const OFFLINE_PURCHASE_DEPOSIT_REFUND_ACTION =
  "purchasedepositofflinerefund";

export const OFFLINE_RESERVATION_DEPOSIT_REFUND_ACTION =
  "reservationdepositofflinerefund";

export const OFFLINE_ORDER_DEPOSIT_REFUND_ACTION = "orderdepositofflinerefund";

export const OFFLINE_HOLD_DEPOSIT_REFUND_ACTION =
  "vehicleholddepositofflinerefund";

export const REFUND_TYPE = {
  PURCHASE_DEPOSIT: "PurchaseDeposit",
  RESERVATION_DEPOSIT: "ReservationDeposit",
  ORDER_DEPOSIT: "OrderDeposit",
  HOLD_DEPOSIT: "HoldDeposit",
};

export const PURCHASE_TYPES = {
  CASH: "CASH",
  CONTINUE_AT_DEALER: "CONTINUE_AT_DEALER",
  CREDIT_APPLICATION: "CREDIT_APPLICATION",
  DISCUSS_WITH_DEALER: "DISCUSS_WITH_DEALER",
  SIGN_AT_DEALER: "SIGN_AT_DEALER",
  SIGN_OFFLINE: "SIGN_OFFLINE",
  SIGN_ONLINE: "SIGN_ONLINE",
  THIRD_PARTY: "THIRD_PARTY",
  DENIED_CREDIT_APPLICATION: "DENIED_CREDIT_APPLICATION",
  PENDING_CREDIT_APPLICATION: "PENDING_CREDIT_APPLICATION",
};

export const PENDING_CREDIT_APP = "CUSTOMER_TIMER_EXPIRED";
export const CONDITIONED_CREDIT_APP = "CONDITIONED";

export const PURCHASE_REQUEST_STATUS = {
  CREATED: "CREATED",
  SOLD: "SOLD",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
};

export const COMPLETED = "COMPLETED";

export const PURCHASE_REQUEST_BUSINESS_STATUS = {
  ACCEPTED: "PURCHASEREQUESTACCEPTED",
  CANCELLED: "PURCHASEREQUESTCANCELLED",
};

export const PURCHASE_REQUEST_PAYMENT_STATUS = {
  ACCEPTED: "ACCEPTED",
  FAILED: "FAILED",
};

export const AUCTION_SITE_MAP = {
  C10: "MD-ELKRIDGE-MANHEIM BALTIMORE/WASHINGTON-C10",
  C11: "IN-PLAINFIELD-ADESA INDIANAPOLIS AUCTION-C11",
  C13: "TX-HOUSTON-MANHEIM TEXAS HOBBY-C13",
  C16: "CA-RIO LINDA-ADESA BRASHERS (SACRAMENTO)-C16",
  C18: "MN-MAPLE GROVE-MANHEIM MINNEAPOLIS-C18",
  C19: "IL-MATTESON-MANHEIM CHICAGO-C19",
  C20: "NY-NEWBURGH-MANHEIM NEW YORK-C20",
  C22: "MS-HATTIESBURG-MANHEIM MISSISSIPPI-C22",
  C23: "NC-GREENSBORO-GREENSBORO AUTO AUCTION-C23",
  C35: "TX-FORT WORTH-MANHEIM DALLAS-FT. WORTH-C35",
  C38: "OH-GROVE CITY-MANHEIM OHIO-C38",
  C40: "TX-SAN ANTONIO-MANHEIM SAN ANTONIO-C40",
  C41: "PA-MANHEIM-MANHEIM AUTO AUCTION-C41",
  C42: "FL-TAMPA-MANHEIM TAMPA-C42",
  C49: "GA-ATLANTA-MANHEIM GEORGIA-C49",
  C55: "FL-DAVIE-MANHEIM FORT LAUDERDALE-C55",
  C63: "NJ-BORDENTOWN-MANHEIM NEW JERSEY-C63",
  C69: "PA-CRANBERRY TOWNSHIP-MANHEIM PITTSBURGH-C69",
  C70: "NY-AKRON-ADESA BUFFALO-C70",
  C75: "HI-HONOLULU-MANHEIM HAWAII-C75",
  C98: "AK-ANCHORAGE-DEALERS AUTO AUCTION OF ALASKA-C98",
  F79: "TN-MT. JULIET-MANHEIM NASHVILLE-F79",
  F80: "MI-CARLETON-MANHEIM DETROIT-F80",
  F81: "MA-FRAMINGHAM-ADESA BOSTON-F81",
  F82: "CO-AURORA-MANHEIM DENVER-F82",
  F84: "MO-KANSAS CITY-MANHEIM KANSAS CITY-F84",
  F87: "CA-FONTANA-MANHEIM SOUTHERN CAL-F87",
  F90: "UT-SALT LAKE CITY-ADESA SALT LAKE-F90",
  F91: "WA-SPOKANE-DAA NORTHWEST-F91",
  F92: "AZ-CHANDLER-ADESA PHOENIX  AA-F92",
  F99: "WA-AUBURN-DAA SEATTLE-F99",
};

export const STATE_LIST = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

// Progress Tracker
// 0: Order Placed, 1: In Production, 2: Shipping, 3: Delivery
export const ORDER_STATUS = {
  1: ["strt", "vdos", "vshp", "prod", "iprl", "grta", "vasc", "vitr"],
  2: [
    "vprt",
    "vhlg",
    "vetl",
    "vmod",
    "vcrb",
    "vand",
    "vcmp",
    "vehs",
    "vafd",
    "vphd",
  ],
};
//0: Pre-Order Placed, 1: Order Placed, 2: In Production, 3: On Its Way, 4: Delivered
export const RESERVATION_STATUS = {
  2: ["strt", "vdos", "vshp", "prod", "iprl", "grta", "vasc", "vitr"],
  3: [
    "vprt",
    "vhlg",
    "vetl",
    "vmod",
    "vcrb",
    "vand",
    "vcmp",
    "vehs",
    "vafd",
    "vphd",
  ],
};

export const DECIMAL_PLACES = 2;

export const CUSTOMER_HANDLING_ROUTES = [
  "orders_requiring_attention",
  "reservations_submitted",
  "orders_submitted",
  "purchase_request",
];
export const REGEX_LIST = {
  number: /^\d*\.?(\.\d{1,2})?$/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  zip: /^\d{5}(-\d{4})?$/,
};

export const DEALER_SETUP =
  "https://ecommdealersetup.dealerconnection.com/vue/dashboard";
export const RESOURCE_CENTER =
  "https://ecommdealersetup.dealerconnection.com/vue/tools";

export const PURCHASE_INFO_CONTRACT_STATUS = {
  SIGNED: "CONTRACT_SIGNED",
};
export const MARKET_LANGUAGES = {
  BEL: ["nl", "fr"],
  CHE: ["fr", "de"],
  CAN: ["en", "fr"],
  THA: ["en", "th"],
};
export const LANCODES = {
  en: "ENGLISH",
  fr: "FRENCH",
  de: "GERMAN",
  nl: "DUTCH",
  no: "NORWEGIAN",
  es: "SPANISH",
  it: "ITALIAN",
  fi: "FINNISH",
  da: "DANISH",
  pt: "PORTUGUESE",
  th: "THAI",
  vi: "VIETNAMESE",
  ph: "FILIPINO",
};

// Regions for translation tool, added New market under IMG
export const TRANSLATION_TOOL_REGIONS = {
  EU: [
    "GBR",
    "DNK",
    "AUT",
    "DEU",
    "CHE",
    "ESP",
    "FIN",
    "BEL",
    "FRA",
    "ITA",
    "NLD",
    "NOR",
  ],
  CAN: ["CAN"],
  IMG: ["VNM", "ZAF", "THA", "PHL", "AUS", "NZL"],
  SA: ["BRA", "ARG", "MEX", "COL", "PER", "CHL"],
};

// Added for payment threshold validation
export const PAYMENT_THRESHOLD_LIMIT = {
  VNM: { maxLimit: "999999999.99", maxLimitFormatted: "999,999,999.99" },
  ZAF: { maxLimit: "99999.99", maxLimitFormatted: "99,999.99" },
  THA: { maxLimit: "99999.99", maxLimitFormatted: "99,999.99" },
  PHL: { maxLimit: "99999.99", maxLimitFormatted: "99,999.99" },
  AUS: { maxLimit: "9999.99", maxLimitFormatted: "9,999.99" },
  NZL: { maxLimit: "9999.99", maxLimitFormatted: "9,999.99" },
};
