import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { displayError } from "../../../../errors/ErrorConstants";
import UploadDealerPricing from "./UploadDealerPricing";
import UploadDealerPriceClient from "../../../../shared/clients/UploadDealerPriceClient";
import ConfirmDialog from "../../../../shared/confirmDialog/ConfirmDialog";

class DealerPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: [],
      dealerInventoryPrices: [],
      hideUsdPrice: false,
      openDeleteDialog: false,
      delete_vin: "",
      sortExpression: undefined,
      filters: [],
    };

    this.dialogProps = {
      confirm: props.t("UploadDealerPrice.confirm"),
      cancel: props.t("UploadDealerPrice.cancel"),
      bodyText: props.t("UploadDealerPrice.bodyTextConfirmDelete"),
    };
    this.pricingRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.user && this.props.user.market !== "ARG") {
      this.setState({ hideUsdPrice: true });
    }
    this.loadDealerInventoryPricingHistory();
  }

  handleSubmit = () => {
    this.pricingRef.current.submit();
  };

  handleClose = () => {
    //Step-3 // Close the confirmation dialog
    this.setState({ openDeleteDialog: false, delete_vin: "" });
  };

  handleDeleteConfirmation = () => {
    //Step-3 // delete the record from DB
    const vin = this.state.delete_vin;
    //show loading icon
    this.props.hideOrShow(true);
    UploadDealerPriceClient.deleteInventoryPrices(vin, this.props.user)
      .then((data) => {
        //hide loading icon
        this.props.hideOrShow(false);
        if (data.message.code === "200") {
          //Step-4 // hide the confirmation dialog
          this.setState({ openDeleteDialog: false });
          //Step-5 // reload the data
          this.loadDealerInventoryPricingHistory();
        }
      })
      .catch(() => {
        //hide loading icon
        this.props.hideOrShow(false);
      });
  };

  uncheckEverything = () => {
    const prices = this.state.dealerInventoryPrices;
    prices.forEach((price) => {
      price.checked = false;
    });
    this.setState({ dealerInventoryPrices: prices });
  };

  loadDealerInventoryPricingHistory(expression) {
    // we are always doing local sorting so make it true
    if (expression) {
      // If user apply sorting
      const records = this.state.dealerInventoryPrices;
      const sortedData = this.getSortedData(expression, records);
      this.setState({
        dealerInventoryPrices: sortedData,
        sortExpression: expression,
      });
      this.setState({ isDataLoaded: true });
      this.props.hideOrShow(false);
    } else {
      // default case
      UploadDealerPriceClient.retrieveDealerInventoryPrices(this.props.user)
        .then((data) => {
          if (this.state.sortExpression) {
            const records =
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData;
            const sortedData = this.getSortedData(
              this.state.sortExpression,
              records
            );
            this.setState({
              dealerInventoryPrices: sortedData,
              all_dealerInventoryPrices: sortedData,
            });
          } else {
            this.setState({
              dealerInventoryPrices:
                data &&
                data.dealerPricesDataList &&
                data.dealerPricesDataList[0].dealerInvPriceData,
              all_dealerInventoryPrices:
                data &&
                data.dealerPricesDataList &&
                data.dealerPricesDataList[0].dealerInvPriceData,
            });
          }
        })
        .finally(() => {
          this.getFilterValues();
          this.props.hideOrShow(false);
        });
    }
  }

  reloadWithFilteredData(expression) {
    // If user apply filter
    const records = [...this.state.all_dealerInventoryPrices];
    if (expression.property === "all") {
      this.setState({
        dealerInventoryPrices: records,
      });
    } else {
      const filteredData = records.filter(
        (currentRecord) =>
          currentRecord[expression.property] === expression.value
      );
      this.setState({
        dealerInventoryPrices: filteredData,
      });
    }
  }

  getFilterValues = () => {
    const records = this.state.all_dealerInventoryPrices;
    const filters = [
      { text: this.props.t("UploadDealerPrice.allVehicles"), value: "all.all" },
      {
        text: this.props.t("UploadDealerPrice.zeroPrice"),
        value: "currencyAdjustedPrice.0",
      },
      {
        text: this.props.t("UploadDealerPrice.unPublishedInventory"),
        value: "published.false",
      },
    ];

    const flags = [],
      recordCount = records.length;

    for (let i = 0; i < recordCount; i++) {
      if (flags[records[i].modelYear]) {
        continue;
      }
      flags[records[i].modelYear] = true;
      const model = {
        text:
          this.props.t("UploadDealerPrice.modelYear") +
          " " +
          records[i].modelYear,
        value: "modelYear." + records[i].modelYear,
      };
      filters.push(model);
    }

    this.setState({ filters: filters });
  };

  dynamicSort(property, dir) {
    let sortOrder = 1;
    if (dir === "DSC") {
      sortOrder = -1;
    }
    return function (firstElement, secondElement) {
      const result =
        firstElement[property] < secondElement[property]
          ? -1
          : firstElement[property] > secondElement[property]
          ? 1
          : 0;
      return result * sortOrder;
    };
  }

  getSortedData(sortExpression, records) {
    const sortProperty = sortExpression.split(".")[0];
    const sortDirection = sortExpression.split(".")[1];

    records.sort(this.dynamicSort(sortProperty, sortDirection));
    return records;
  }

  getDealerList() {
    const dealers = [];
    let dealerList = this.props.user.getCommonIdsWithoutSDLR();
    if (dealerList) {
      dealerList = dealerList.split(",");
      dealerList.forEach((dealer) => {
        const formattedDealer = this.props.user.reformatCommonId(dealer);
        dealers.push(
          this.props.user.dealerGroupingsMap[formattedDealer].completeDealerId
        );
      });
    }
    return dealers;
  }

  showInventoryConfirmation = (v) => {
    //Step-2 // Open the confirmation dialog
    this.setState({ openDeleteDialog: true, delete_vin: v });
  };

  render() {
    if (displayError(this.state.hasError)) {
      throw new Error(this.state.hasError[0]);
    }

    return (
      <div>
        {this.state.isDataLoaded && (
          <>
            <UploadDealerPricing
              {...this.props}
              enableUpload={true}
              loadDealerInventoryPricingHistory={(filterExpression) =>
                this.loadDealerInventoryPricingHistory(filterExpression)
              }
              dealerInventoryPrices={this.state.dealerInventoryPrices}
              hideUsdPrice={this.state.hideUsdPrice}
              uncheckEverything={this.uncheckEverything}
              showInventoryConfirmation={this.showInventoryConfirmation}
              filters={this.state.filters}
              reloadWithFilteredData={(filterExpression) =>
                this.reloadWithFilteredData(filterExpression)
              }
            />
            <ConfirmDialog
              open={this.state.openDeleteDialog}
              dialogProps={this.dialogProps}
              onConfirm={this.handleDeleteConfirmation}
              onCancel={this.handleClose}
              onClose={this.handleClose}
              boldTitle={false}
              {...this.props}
            />
          </>
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(DealerPricing);
