/**
 * @return {string}
 */

export default function UpperCaseText(text) {
  if (text) {
    return text.toUpperCase();
  } else {
    return "";
  }
}
