import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styles from "../dealerDashBoard/DashboardStyles.module.scss";
import dashboardStyles from "../dealerDashBoard/dashboardStyles";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import { withTranslation } from "react-i18next";
import DealerNotificationsClient from "../../../shared/clients/DealerNotificationsClient";
import UiDateUtils from "../../../utils/UiDateUtils";
import UpperCaseText from "../../../utils/UpperCaseText";
import HasPermissionTo from "../../../common/HasPermissionTo";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import crStyles from "../../customerReservation/crStyles";
import NavigateNext from "@material-ui/icons/NavigateNext";

class DealerNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      sortDir: undefined,
      sortKey: undefined,
      renderApp: false,
      totalCount: 0,
      totalPages: 0,
      currentPage: 0,
      hasNext: false,
      hasPrevious: false,
    };
    this.hasError = false;
  }

  componentDidMount() {
    this.props.hideOrShow(true, true);
    DealerNotificationsClient.getDealerNotifications(
      this.props.user,
      this.state.currentPage
    )
      .then((data) => {
        this.populateState(data);
      })
      .catch(() => {
        //this.hasError = true;
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ renderApp: true });
      });
  }

  handlePrevious = () => {
    DealerNotificationsClient.getDealerNotifications(
      this.props.user,
      this.state.currentPage - 1
    )
      .then((data) => {
        this.populateState(data);
      })
      .catch()
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  handleNext = () => {
    DealerNotificationsClient.getDealerNotifications(
      this.props.user,
      this.state.currentPage + 1
    )
      .then((data) => {
        this.populateState(data);
      })
      .catch()
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  handleSort(sortKey) {
    this.state.notifications.map((item) => item.type);

    let newDirection = "DSC";
    if (sortKey === this.state.sortKey) {
      newDirection = this.state.sortDir === "ASC" ? "DSC" : "ASC";
    }
    this.setState({ sortDir: newDirection, sortKey });

    if (sortKey === "status") {
      sortKey = "value";
    }
    if (sortKey === "sentDate") {
      this.sortDate(newDirection);
    } else {
      this.sortAlphabetical(sortKey, newDirection);
    }
  }

  populateState = (data) => {
    this.setState({
      notifications: data.ordersData,
      totalCount: data.pagination.totalCount,
      totalPages: data.pagination.totalPages,
      currentPage: data.pagination.page,
      hasNext: data.pagination.hasNext,
      hasPrevious: data.pagination.hasPrevious,
    });
  };

  markNotificationRead = (id) => {
    DealerNotificationsClient.markNotificationStatusRead(this.props.user, id);
  };

  deleteNotification = (id) => {
    DealerNotificationsClient.deleteNotification(this.props.user, id).then(
      () => {
        this.setState((prevState) => ({
          notifications: this.state.notifications.filter(
            (notification) => notification.uid !== id
          ),
          totalCount: prevState.totalCount - 1,
        }));
      }
    );
  };

  sortAlphabetical(sortKey, newDir) {
    const notificationsCopy = JSON.parse(
      JSON.stringify(this.state.notifications)
    );

    if (newDir === "ASC") {
      notificationsCopy.sort((a, b) => {
        if (a[sortKey] > b[sortKey]) {
          return 1;
        } else if (a[sortKey] === b[sortKey]) {
          return 0;
        } else {
          return -1;
        }
      });
    } else {
      notificationsCopy.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return 1;
        } else if (a[sortKey] === b[sortKey]) {
          return 0;
        } else {
          return -1;
        }
      });
    }
    this.setState({ notifications: notificationsCopy });
  }

  sortDate(newDir) {
    const notificationsCopy = JSON.parse(
      JSON.stringify(this.state.notifications)
    );

    if (newDir === "ASC") {
      notificationsCopy.sort((a, b) => {
        const dateA = a["sentDate"].split("-");
        const dateB = b["sentDate"].split("-");
        const finalDateA = dateA[2] + "-" + dateA[1] + "-" + dateA[0];
        const finalDateB = dateB[2] + "-" + dateB[1] + "-" + dateB[0];
        if (finalDateA > finalDateB) {
          return 1;
        } else if (finalDateA === finalDateB) {
          return 0;
        } else {
          return -1;
        }
      });
    } else {
      notificationsCopy.sort((a, b) => {
        const dateA = a["sentDate"].split("-");
        const dateB = b["sentDate"].split("-");
        const finalDateA = dateA[2] + "-" + dateA[1] + "-" + dateA[0];
        const finalDateB = dateB[2] + "-" + dateB[1] + "-" + dateB[0];
        if (finalDateA < finalDateB) {
          return 1;
        } else if (finalDateA === finalDateB) {
          return 0;
        } else {
          return -1;
        }
      });
    }
    this.setState({ notifications: notificationsCopy });
  }

  isViewSDLRRestricted = (notification) => {
    return this.props.isMainViewingSDLR(
      this.props.user.dealerGroupingsMap[notification.vendorId]
    );
  };

  getType = (notification) => {
    if (
      notification.type &&
      this.isFinanceType(UpperCaseText(notification.type))
    ) {
      return this.props.t("DealerDashboard.orderUpdate");
    } else {
      switch (notification.type) {
        // Created
        case "PAID_RESERVATION":
          return UpperCaseText(this.props.t("CustomerReservations.created"));
        case "RESERVATION_CONFIRMED":
          return UpperCaseText(this.props.t("CustomerReservations.created"));
        // Cancelled, Dealer_Cancelled
        case "DEALER_CANCELLED":
        case "CANCELLED":
          return UpperCaseText(this.props.t("CustomerReservations.cancelled"));
        case "RESERVATION_CANCELLED":
          return UpperCaseText(this.props.t("CustomerReservations.cancelled"));
        // Amended
        case "RESERVATION_AMENDED":
          return UpperCaseText(this.props.t("CustomerReservations.amended"));
        case "OFFLINE_AMMEND":
          return UpperCaseText(this.props.t("CustomerReservations.amended"));
        // Ordered, Ordered_Contracted
        case "ORDERED":
        case "ORDER_CONTRACTED":
          return UpperCaseText(this.props.t("CustomerReservations.ordered"));
        case "TRADEIN_ADDED":
          return UpperCaseText(this.props.t("DealerDashboard.orderUpdate"));
        // Otherwise
        default:
          return UpperCaseText(notification.type);
      }
    }
  };

  getIconType = (notification) => {
    // ReportProblemOutlinedIcon - for actions that need to be resolved
    // ErrorOutlineIcon - for any other sort of notification
    if (
      notification.type &&
      this.isFinanceType(UpperCaseText(notification.type))
    ) {
      return this.getFinanceStatusType(UpperCaseText(notification.status)) ===
        "INFO" ? (
        <ErrorOutlineIcon
          data-testid={`infoIcon-${notification.params.reservationId}`}
          className={styles.actionIconOrders}
        />
      ) : (
        <ReportProblemOutlinedIcon
          data-testid={`problemIcon-${notification.params.reservationId}`}
          className={styles.actionIconOrders}
        />
      );
    } else {
      switch (notification.type) {
        // Case statement for future actions that need to be resolved
        case "FAILED_FINANCE":
          return (
            <ReportProblemOutlinedIcon
              data-testid={`problemIcon-${notification.params.reservationId}`}
              className={styles.actionIconOrders}
            />
          );
        default:
          return (
            <ErrorOutlineIcon
              data-testid={`infoIcon-${notification.params.reservationId}`}
              className={styles.actionIconOrders}
            />
          );
      }
    }
  };

  // If specification present, attach to the description cell
  getSpec = (notification) => {
    if (notification.type === "TRADEIN_ADDED") {
      return this.props.t("DealerDashboard.tradeinDescription");
    } else if (notification.specification) {
      return ` - ${notification.specification}`;
    } else if (notification.params.NotificationMsg) {
      return notification.params.NotificationMsg.includes(
        notification.params.reservationId
      )
        ? ` ${notification.params.NotificationMsg.replace(
            notification.params.reservationId,
            ""
          )}`
        : ` - ${notification.params.NotificationMsg}`;
    } else {
      return;
    }
  };

  getFinanceStatusType = (status) => {
    switch (status) {
      case "SUBMITTED":
      case "APPROVED":
      case "CONDITIONED":
      case "CANCELLED":
      default:
        return "INFO";
      case "OPT_OFFLINE":
      case "ERROR":
      case "DENIED":
      case "FINANCE_EID_CHECKED_FAIL":
      case "FINANCE_REFERRED":
        return "ACTION";
    }
  };

  isFinanceType = (type) => {
    return type === "FINANCE_STATUS" || type === "FINANCE_FAILED";
  };

  isLeaseType = (type) => {
    return type === "LEASE_STATUS" || type === "LEASE_FAILED";
  };

  getLinkIconType = (notification) => {
    if (
      (notification.type &&
        this.isFinanceType(UpperCaseText(notification.type))) ||
      this.isLeaseType(UpperCaseText(notification.type))
    ) {
      return this.getFinanceStatusType(UpperCaseText(notification.status)) ===
        "INFO"
        ? this.props.t("DealerDashboard.view")
        : this.props.t("DealerDashboard.gotoPage");
    } else {
      return this.props.t("DealerDashboard.view");
    }
  };

  getFinanceDesc = (notification) => {
    switch (UpperCaseText(notification.status)) {
      case "APPROVED":
        return this.props.t("DealerDashboard.approvedDescription");
      case "SUBMITTED":
        return this.props.t("DealerDashboard.submittedDescription");
      case "CONDITIONED":
        return this.props.t("DealerDashboard.conditionedDescription");
      case "ERROR":
      case "FINANCE_EID_CHECKED_FAIL":
      case "FINANCE_REFERRED":
        return this.props.t("DealerDashboard.errorDescription");
      case "DENIED":
        return this.props.t("DealerDashboard.deniedDescription");
      case "CANCELLED":
        return this.props.t("DealerDashboard.cancelledDescription");
      case "OPT_OFFLINE":
        return this.props.t("DealerDashboard.optofflineDescription");
      default:
        return ` - ${notification.status}`;
    }
  };

  getLeaseDesc = (notification) => {
    switch (UpperCaseText(notification.status)) {
      case "APPROVED":
        return this.props.t("DealerDashboard.approvedDescription");
      case "SUBMITTED":
        return this.props.t("DealerDashboard.leaseSubmittedDescription");
      case "CONDITIONED":
        return this.props.t("DealerDashboard.leaseConditionedDescription");
      case "ERROR":
        return this.props.t("DealerDashboard.errorDescription");
      case "DENIED":
        return this.props.t("DealerDashboard.leaseDeniedDescription");
      case "CANCELLED":
        return this.props.t("DealerDashboard.leaseCancelledDescription");
      case "OPT_OFFLINE":
        return this.props.t("DealerDashboard.optofflineLeaseDescription");
      default:
        return ` - ${notification.status}`;
    }
  };

  render() {
    const onPage =
      this.state.notifications.length > 0 ? this.state.currentPage + 1 : 0;

    function getTableHeader(style, divStyle, column) {
      return (
        <TableCell
          id={column + column}
          style={style}
          // onClick={() => this.handleSort(column)}
        >
          <div className={divStyle}>
            {this.props.t("OrdersDashboard." + column)}
            {/*<SortingIcon*/}
            {/*  currentKey={column}*/}
            {/*  sortKey={this.state.sortKey}*/}
            {/*  sortDirection={this.state.sortDir}*/}
            {/*/>*/}
          </div>
        </TableCell>
      );
    }

    let orders = [];
    if (this.state.notifications.length > 0) {
      orders = this.state.notifications;
    }
    return (
      this.state.renderApp &&
      (this.hasError ? (
        <div
          style={dashboardStyles.header}
          className={styles.notificationHeader}
        >
          {this.props.t("DealerDashboard.ordersHeader")}
          <h2>{this.props.t("InvalidRoute.header")}</h2>
        </div>
      ) : (
        <div>
          <div
            style={dashboardStyles.header}
            className={styles.notificationHeader}
          >
            {this.props.t("DealerDashboard.ordersHeader")} (
            <span data-testid="allNotificationCount">
              {this.state.totalCount}
            </span>
            )
          </div>
          <Table data-testid="notificationsTable" key="dealerDashBoardTable">
            <TableHead>
              <TableRow
                style={dashboardStyles.dashboardHeader}
                className={styles.tableHeaderLine}
              >
                {getTableHeader.call(
                  this,
                  dashboardStyles.typeHeader,
                  styles.alignStart,
                  "type",
                  0
                )}
                {getTableHeader.call(
                  this,
                  dashboardStyles.orderIdHeader,
                  styles.alignStart,
                  "description",
                  1
                )}
                {getTableHeader.call(
                  this,
                  dashboardStyles.dateHeader,
                  styles.alignStart,
                  "pendingDueDate",
                  2
                )}
                <TableCell style={dashboardStyles.actionHeader}>
                  <div className={styles.alignStart}>
                    {" "}
                    {this.props.t("OrdersDashboard.action")}{" "}
                  </div>
                </TableCell>
                <TableCell style={dashboardStyles.emptyHeader} />
              </TableRow>
            </TableHead>

            <TableBody>
              {orders.map((notification, index) => (
                <TableRow
                  className={
                    notification.siteMessageStatus === "READ"
                      ? styles.textContentBodyListRead
                      : styles.textContentBodyListNew
                  }
                  id={"row" + index}
                  key={index}
                >
                  <TableCell style={dashboardStyles.leftCell}>
                    {this.getIconType(notification)}
                    {(notification.value = this.getType(notification))}
                  </TableCell>
                  <TableCell style={dashboardStyles.centerCell}>
                    {notification.params.reservationId}
                    {this.isFinanceType(notification.type)
                      ? this.getFinanceDesc(notification)
                      : this.isLeaseType(notification.type)
                      ? this.getLeaseDesc(notification)
                      : this.getSpec(notification)}
                  </TableCell>
                  <TableCell
                    className={styles.col3}
                    style={dashboardStyles.centerCell}
                  >
                    {notification.sentDate
                      ? UiDateUtils.formattedDate(notification.sentDate)
                      : this.props.t("DealerDashboard.noDeadline")}
                    {this.getFinanceStatusType(
                      UpperCaseText(notification.status)
                    ) === "INFO"
                      ? ""
                      : this.props.t("DealerDashboard.mediumPriority")}
                  </TableCell>

                  <TableCell
                    style={dashboardStyles.centerCell}
                    id="reservation"
                    className={styles.col4}
                  >
                    <Link
                      id="reservationLink"
                      onClick={() => {
                        this.markNotificationRead(notification.uid);
                      }}
                      to={`/dealer/${notification.vendorId}/customer-reservations/order-details/${notification.params.reservationId}`}
                      className={styles.tableLink}
                    >
                      {this.getLinkIconType(notification)}
                      <ChevronRightIcon style={dashboardStyles.viewIcon} />
                    </Link>
                  </TableCell>

                  <HasPermissionTo
                    permissions={this.props.user.permissions.rolePermissions}
                    perform={["deleteNotification"]}
                    condition={!this.isViewSDLRRestricted(notification)}
                    render={() => {
                      return (
                        <TableCell
                          data-testid={`deleteNotification${index}`}
                          style={dashboardStyles.rightCell}
                          className={styles.cursorButton}
                          onClick={() =>
                            this.deleteNotification(notification.uid)
                          }
                        >
                          <div className={styles.closeButton}>
                            <Clear />
                          </div>
                        </TableCell>
                      );
                    }}
                    noRender={() => {
                      return <TableCell>&nbsp;</TableCell>;
                    }}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={styles.pagesDiv}>
            <button
              disabled={this.state.hasPrevious === false}
              data-testid="prev"
              onClick={() => this.handlePrevious()}
              className={styles.buttonStyle}
            >
              <NavigateBefore
                style={
                  this.state.hasPrevious === false
                    ? crStyles.arrowDisabled
                    : crStyles.arrowEnabled
                }
              />
            </button>
            <div className={styles.pages}>
              {onPage} / {this.state.totalPages}
            </div>
            <button
              disabled={this.state.hasNext === false}
              data-testid="next"
              onClick={() => this.handleNext()}
              className={styles.buttonStyle}
            >
              <NavigateNext
                style={
                  this.state.hasNext === false
                    ? crStyles.arrowDisabled
                    : crStyles.arrowEnabled
                }
              />
            </button>
          </div>
        </div>
      ))
    );
  }
}

export default withTranslation("emp")(DealerNotifications);
