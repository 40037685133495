import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import {
  INVENTORY_SERVICE_URL,
  RESERVATION_BASE_URL,
} from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerReportingClient {
  static baseUrl = RESERVATION_BASE_URL;
  static inventoryServiceUrl = INVENTORY_SERVICE_URL;

  static downloadOrderStatusReportInCsv(user, lang) {
    const commonIds = user.getCommonIdsWithSDLR
      ? user.getCommonIdsWithSDLR().split(",")
      : user.commonId.split("|").join("%7C");
    const url = `${this.baseUrl}/dealer/order-status-report?dealerCommonId=${commonIds}&lang=${lang}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadPurchaseRequestReportInCsv(user, lang) {
    const commonIds = user.getCommonIdsWithSDLR
      ? user.getCommonIdsWithSDLR().split(",")
      : user.commonId.split("|").join("%7C");
    const url = `${this.baseUrl}/dealer/purchase-request-report?dealerCommonId=${commonIds}&lang=${lang}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadDealerReservationReportInCsv(user, lang) {
    const commonIds = user.getCommonIdsWithSDLR
      ? user.getCommonIdsWithSDLR().split(",")
      : user.commonId.split("|").join("%7C");
    const url = `${this.baseUrl}/dealer/dealer-reservation-report?dealerCommonId=${commonIds}&lang=${lang}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadOrderConversionReportInCsv(user, lang) {
    const commonIds = user.getCommonIdsWithSDLR
      ? user.getCommonIdsWithSDLR().split(",")
      : user.commonId.split("|").join("%7C");
    const url = `${this.baseUrl}/dealer/dealer-order-conversion-report?dealerCommonId=${commonIds}&lang=${lang}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadInventoryPricingReportInCsv(
    user,
    inventoryReportColumnHeadersForDealer
  ) {
    const dealerId = user.getDealerIdsWithSDLR(false);
    const data = {
      columnHeaders: inventoryReportColumnHeadersForDealer,
    };
    const url = `${this.inventoryServiceUrl}/dealer/${dealerId}/inventory-pricing-report`;
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadInventoryPricingHistoryReport(
    user,
    inventoryReportColumnHeadersForDealer
  ) {
    const dealerId = user.getDealerIdsWithSDLR(false);
    const data = {
      columnHeaders: inventoryReportColumnHeadersForDealer,
    };
    const url = `${this.inventoryServiceUrl}/dealer/${dealerId}/inventory-pricing-history-report`;
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
