export const PAGE_ERROR = "PAGE_ERROR";

export const ERROR_PROCESSING = "ERROR_PROCESSING";

export const LOAD_ERROR = "LOAD_ERROR";

export const is401 = (error) => {
  return error && error.response && error.response.status === 401;
};

export const is400 = (error) => {
  return error && error.response && error.response.status === 400;
};

export const is404 = (error) => {
  return error && error.response && error.response.status === 404;
};

export const is500 = (error) => {
  return error && error.response && error.response.status === 500;
};

export const isUnknownError = (error) => {
  return error === null || error === undefined;
};

export const isProcessingError = (error) => {
  return is400(error) || is404(error) || is500(error) || isUnknownError(error);
};

export const displayError = (errors) => {
  return errors && errors.length > 0;
};

export const ERROR_CODES = [
  "SAP-FORDPAY400",
  "SAP-FORDPAY500",
  "SAP-FORDPAY300",
  "SAP-FORDPAY100",
];

export const isFordPayError = async (error) => {
  await setTimeout(function () {}, 5000);
  const parsedError = JSON.parse(
    error.response && error.response.data && error.response.data.message
  );
  if (
    parsedError.errors &&
    parsedError.errors[0] &&
    parsedError.errors[0].payload
  ) {
    return parsedError.errors[0].payload.code;
  }
};
