import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import styles from "./BusinessSettings.module.scss";
import { Redirect } from "react-router-dom";
import DealerMembershipStatusClient from "../../shared/clients/DealerMembershipStatusClient";
import HasPermissionTo from "../../common/HasPermissionTo";
import MultiToggleWidget from "../../shared/toggles/multiToggleWidget/MultiToggleWidget";
import ConfirmDialog from "../../shared/confirmDialog/ConfirmDialog";

class MembershipPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDealerActive: this.props.dealer.status === "ACTIVE",
      openDialog: false,
    };
    this.deactivateDialogProps = {
      title: this.props.t("EmpActivation.confirmDealerDeactivationTitle"),
      bodyText: this.props.t("EmpActivation.confirmDealerDeactivationText"),
      confirm: this.props.t("EmpActivation.confirmDealerDeactivationButton"),
      cancel: this.props.t("EmpActivation.cancelDealerDeactivationButton"),
    };
    this.activateDialogProps = {
      title: this.props.t("EmpActivation.confirmDealerActivationTitle"),
      bodyText: this.props.t("EmpActivation.confirmDealerActivationText"),
      confirm: this.props.t("EmpActivation.confirmDealerActivationButton"),
      cancel: this.props.t("EmpActivation.cancelDealerActivationButton"),
    };
  }

  handleEmpMembershipChange = (dealerId, event) => {
    const buttonStatus = event.target.checked;

    if (
      (this.state.isDealerActive && buttonStatus) ||
      (!this.state.isDealerActive && !buttonStatus)
    ) {
      return;
    }
    if (buttonStatus) {
      this.setState({ openDialog: true });
    } else {
      this.setState({ openDialog: true });
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
          }}
        />
      );
    }
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleConfirmation = () => {
    DealerMembershipStatusClient.toggleEmpMembership(
      this.props.user,
      this.props.dealer.completeDealerId,
      !this.state.isDealerActive
    )
      .then(() => {
        this.setState({
          isDealerActive: !this.state.isDealerActive,
          openDialog: false,
        });
      })
      .finally(() => {
        this.props.dealer.status = this.state.isDealerActive
          ? "ACTIVE"
          : "INACTIVE";
        this.props.updateDealerEmpMembership(this.props.dealer);
      });
  };

  render() {
    const dealerId = this.props.dealer.dealerId;
    return (
      <Grid container className={styles.activationPanel}>
        <Grid item md={9}>
          <div className={`${styles.dealerName}`}>
            {`${dealerId}`} | {`${this.props.dealer.name}`}
          </div>
          <ConfirmDialog
            open={this.state.openDialog}
            dialogProps={
              this.state.isDealerActive
                ? this.deactivateDialogProps
                : this.activateDialogProps
            }
            onConfirm={this.handleConfirmation}
            onCancel={this.handleClose}
            onClose={this.handleClose}
            boldTitle={false}
            {...this.props}
          />
        </Grid>
        <Grid item md={3}>
          <HasPermissionTo
            perform={["empMembership:write"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <MultiToggleWidget
                {...this.props}
                isToggleDisabled={this.props.isMainViewingSDLR(
                  this.props.dealer
                )}
                toggleName={`empMembership_${dealerId}`}
                settings={{
                  acceptedText: this.props.t("EmpMembership.active"),
                  rejectedText: this.props.t("EmpMembership.inactive"),
                  suppClass: `${styles.toggleWrapperMain}`,
                  toggleLabel: `${styles.activationToggle}`,
                }}
                currentStatus={this.state.isDealerActive ? "yes" : "no"}
                onSelect={(e) => this.handleEmpMembershipChange(dealerId, e)}
              />
            )}
            noRender={() => (
              <div
                data-testid={`disabledMembershipText_${dealerId}`}
                className={`${styles.switches} switch-3 switch-candy`}
              >
                {this.state.isDealerActive
                  ? this.props.t("EmpMembership.active")
                  : this.props.t("EmpMembership.inactive")}
              </div>
            )}
          />
        </Grid>
      </Grid>
    );
  }
}

class EmpMembership extends Component {
  render() {
    return (
      <div>
        <div id="membershipHeader" className={`${styles.membershipTitle}`}>
          {this.props.t("EmpMembership.membershipHeader")}
        </div>
        <div>
          {/*"this.props.filteredDealers" has only Onboarded dealers.
          "this.props.dealerGroup" has all types of dealers */}
          {Object.keys(this.props.dealerGroup).length !== 0
            ? Object.keys(this.props.dealerGroup).map((commonId) => {
                return (
                  <MembershipPanel
                    key={`panelKey_${commonId}`}
                    {...this.props}
                    dealer={this.props.dealerGroup[commonId]}
                  />
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(EmpMembership);
